import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from 'app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../app/deprecated/store/store';
import $api from '../../../../../shared/api/api';
import { Link, LinkResponse } from '../../types/link';
import { camelCaseObject } from '../../../../../shared/lib/utils/formatting/formatResponse';
import { linkFormActions } from '../../slices/linkFormSlice';
import { jobsActions } from '../../../../Job';
import { LinkDownloaderStatus } from '../../types/linkDownloaderStatus';

export const reloadRunningLink = createAsyncThunk<void, string, ThunkConfig<string>>(
  'link/reloadRunningLink',
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.get<LinkResponse>(`/link/${id}`);

      dispatch(linkFormActions.setData(camelCaseObject(response.data) as Link));
      if (response.data.downloader_status === LinkDownloaderStatus.Downloaded) {
        dispatch(jobsActions.setNeedReloadJobs(true));
      }
    } catch (err) {
      const errorText = `Ошибка при обновлении загрузки по ссылке: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
