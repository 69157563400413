import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from 'app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from 'app/deprecated/store/store';
import $api from 'shared/api/api';
import { LinkResponse, UpdateLinkInput } from '../../types/link';
import omit from 'lodash/omit';

export const updateLink = createAsyncThunk<LinkResponse, UpdateLinkInput, ThunkConfig<string>>(
  'link/updateLink',
  async (input, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.put<LinkResponse>(`/link/${input.id}`, omit(input, 'id'));

      dispatch(
        openSnackbar({
          open: true,
          message: 'Ссылка обновлена',
          variant: 'alert',
          alert: {
            color: 'success',
          },
        }),
      );

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при обновлении ссылки: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
