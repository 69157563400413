import React, { memo, useEffect, useMemo } from 'react';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import { getJobSubtasksMap } from '../../model/selectors/jobsSelectors';
import {
  DATE_WITH_TIME_FORMAT,
  getDateString,
} from '../../../../shared/lib/utils/formatting/formatDate';
import cn from 'classnames';
import cls from './JobSubtasksTable.module.scss';
import { JobResultEnum, JobResultRuMap } from '../../model/lib/jobsConstants';
import { fetchJobSubtasks } from '../../model/services/fetchJobSubtasks/fetchJobSubtasks';
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTable } from 'react-table';

interface JobSubtasksTableProps {
  className?: string;
  jobId: string;
}

export const JobSubtasksTable = memo((props: JobSubtasksTableProps) => {
  const { className, jobId } = props;

  const dispatch = useAppDispatch();
  const jobSubtasksMap = useAppSelector(getJobSubtasksMap);
  const data = jobSubtasksMap[jobId]?.jobSubtasks ?? [];
  const loading = jobSubtasksMap[jobId]?.loading;

  const columns = useMemo(
    () => [
      {
        Header: 'Id подзадачи',
        accessor: 'subtaskId',
      },
      {
        Header: 'Создано',
        accessor: 'createdAt',
        Cell: ({ value }: any) => <span>{getDateString(value, DATE_WITH_TIME_FORMAT)}</span>,
      },
      {
        Header: 'Обновлено',
        accessor: 'updatedAt',
        Cell: ({ value }: any) => <span>{getDateString(value, DATE_WITH_TIME_FORMAT)}</span>,
      },
      {
        Header: 'Сообщение',
        accessor: 'message',
      },
      {
        Header: 'Статус',
        accessor: 'result',
        Cell: ({ value }: any) => (
          <div
            className={cn(
              cls.status,
              value === JobResultEnum.Completed && cls.statusCompleted,
              value === JobResultEnum.Error && cls.statusError,
            )}
          >
            {JobResultRuMap[value as JobResultEnum] ?? value}
          </div>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
  });

  useEffect(() => {
    dispatch(fetchJobSubtasks(jobId));
  }, [dispatch, jobId]);

  if (loading) {
    return (
      <Table className={className} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {[0, 1, 2].map((item: number) => (
            <TableRow key={item}>
              {[0, 1, 2, 3, 4, 5].map((col: number) => (
                <TableCell key={col}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <Table className={className} {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <TableCell {...column.getHeaderProps([{ className: column.className }])}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>
                  {cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
});
