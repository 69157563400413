import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import { SupplierResponse } from '../../../../../../entities/Supplier';
import { UpdateSupplierInput } from '../../types/updateSupplier';

export const updateSupplier = createAsyncThunk<
  SupplierResponse,
  { id: string; input: UpdateSupplierInput },
  ThunkConfig<string>
>('supplier/updateSupplier', async ({ id, input }, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const response = await $api.put<SupplierResponse>(`/supplier/${id}`, input);

    dispatch(
      openSnackbar({
        open: true,
        message: 'Поставщик обновлен',
        variant: 'alert',
        alert: {
          color: 'success',
        },
      }),
    );

    return response.data;
  } catch (err) {
    const errorText = `Ошибка при обновлении поставщика: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
