import { createSlice } from '@reduxjs/toolkit';
import { camelCaseObject } from '../../../../shared/lib/utils/formatting/formatResponse';
import { Job } from '../types/job';
import { fetchJob } from '../services/fetchJob/fetchJob';

interface JobState {
  data?: Job;
  loading: boolean;
}

const initialState: JobState = {
  data: undefined,
  loading: false,
};

export const jobSlice = createSlice({
  name: 'jobSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchJob
    builder.addCase(fetchJob.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchJob.fulfilled, (state, action) => {
      state.data = camelCaseObject(action.payload) as Job;
      state.loading = false;
    });
    builder.addCase(fetchJob.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { actions: jobActions } = jobSlice;
export const { reducer: jobReducer } = jobSlice;
