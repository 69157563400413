// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackLink_backLink__xi2jQ {\n  margin-bottom: 20px;\n}\n\n.BackLink_backLinkIcon__3AA4b {\n  margin-right: 8px;\n}", "",{"version":3,"sources":["webpack://src/shared/ui/deprecated/BackLink/BackLink.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".backLink {\n  margin-bottom: 20px;\n}\n\n.backLinkIcon {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backLink": "BackLink_backLink__xi2jQ",
	"backLinkIcon": "BackLink_backLinkIcon__3AA4b"
};
export default ___CSS_LOADER_EXPORT___;
