import { useCallback, useState } from 'react';
import $api from '../../../api/api';
import { openSnackbar } from '../../../../app/deprecated/store/reducers/snackbarSlice';
import { useAppDispatch } from '../useAppDispatch/useAppDispatch';
import { getFileNameFromHeaders } from '../../utils/files/getFileNameFromHeaders/getFileNameFromHeaders';

export function useDownloadFile(): [
  (apiLink: string, fileName?: string) => () => Promise<void>,
  boolean,
] {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleDownloadFile = useCallback(
    (apiLink: string, fileName?: string) => {
      return async () => {
        try {
          setLoading(true);

          const response = await $api.get(apiLink, {
            responseType: 'blob',
          });

          const url = URL.createObjectURL(
            new Blob([response.data], { type: response.data.type ?? 'application/octet-stream' }),
          );
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', fileName ?? getFileNameFromHeaders(response.headers));
          link.style.display = 'none';

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);

          URL.revokeObjectURL(url);
        } catch (err) {
          dispatch(
            openSnackbar({
              open: true,
              message: `Ошибка при скачивании файла: ${
                err instanceof Error ? err.message : String(err)
              }`,
              variant: 'alert',
              alert: {
                color: 'error',
              },
            }),
          );
        } finally {
          setLoading(false);
        }
      };
    },
    [dispatch],
  );

  return [handleDownloadFile, loading];
}
