import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { REFRESH_TOKEN_LOCAL_STORAGE_KEY, TOKEN_LOCAL_STORAGE_KEY } from '../const/localStorage';
import { logout } from '../../app/deprecated/store/reducers/authSlice';

const API_URL = process.env.REACT_APP_API_URL;

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
    if (token) {
      headers.set('Authorization', `Bearer ${localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: 'refresh',
            method: 'POST',
            body: JSON.stringify({
              token: localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY),
              refresh_token: localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY),
            }),
          },
          api,
          extraOptions,
        );
        if (refreshResult.data) {
          localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, refreshResult.data.token);
          localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refreshResult.data.refresh_token);
          result = await baseQuery(args, api, extraOptions);
        } else {
          console.log('Пользователь не авторизован');
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const rtkApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
