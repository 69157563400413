import React, { memo } from 'react';
import MuiListItem, { ListItemProps as MuiListItemProps } from '@mui/material/ListItem';
import {
  ListItemIconProps as MuiListItemIconProps,
  ListItemTextProps as MuiListItemTextProps,
  SvgIcon,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { CSSObject, styled, useTheme } from '@mui/material/styles';
import { useAppSelector } from '../../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import { getNavigationItems } from '../../model/selectors/navigation';

interface ListItemProps extends MuiListItemProps {
  open: boolean;
  selected: boolean;
}

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'selected',
})<ListItemProps>(
  ({ theme, open, selected }): CSSObject => ({
    minHeight: 56,
    padding: theme.spacing(0, 1.5),
    justifyContent: 'center',
    '&:hover .icon': {
      color: selected ? theme.palette.blueHover.main : theme.palette.grayHover.main,
    },
    ...(open && {
      justifyContent: 'initial',
      backgroundColor: selected ? theme.palette.grayBg.main : theme.palette.white.main,
      '&:hover': {
        backgroundColor: theme.palette.hover.main,
        '& .MuiListItemText-primary': {
          color: theme.palette.black.main,
        },
      },
    }),
    ...(!open && {
      justifyContent: 'initial',
      backgroundColor: theme.palette.white.main,
      '&:hover': {
        backgroundColor: theme.palette.white.main,
      },
    }),
  }),
);

interface ListItemIconProps extends MuiListItemIconProps {
  open: boolean;
  selected: boolean;
}

const ListItemIcon = styled(MuiListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'selected',
})<ListItemIconProps>(
  ({ theme, open, selected }): CSSObject => ({
    boxSizing: 'border-box',
    minWidth: 0,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 32,
    backgroundColor: selected ? theme.palette.white.main : 'transparent',
    border: `1px solid ${selected ? theme.palette.border.main : 'transparent'}`,
    borderRadius: 10,
    ...(open && {
      marginRight: theme.spacing(1.5),
      '& .icon': {
        color: selected ? theme.palette.blue.main : theme.palette.gray.main,
      },
    }),
    ...(!open && {
      marginRight: 'auto',
      '& .icon': {
        color: selected ? theme.palette.blue.main : theme.palette.gray.main,
      },
    }),
  }),
);

interface ListItemTextProps extends MuiListItemTextProps {
  open: boolean;
}

const ListItemText = styled(MuiListItemText, {
  shouldForwardProp: (prop) => prop !== 'open',
})<ListItemTextProps>(
  ({ theme, open }): CSSObject => ({
    color: theme.palette.gray.main,
    ...(open && {
      opacity: 1,
    }),
    ...(!open && {
      opacity: 0,
    }),
  }),
);

interface NavigationDrawerListProps {
  open: boolean;
}

export const NavigationDrawerList = memo((props: NavigationDrawerListProps) => {
  const { open } = props;

  const theme = useTheme();
  const items = useAppSelector(getNavigationItems);

  return (
    <List component="nav" sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
      {items.map((item) => (
        <NavLink key={item.url} to={item.url} style={{ textDecoration: 'none', color: 'inherit' }}>
          {({ isActive }) => (
            <ListItem open={open} selected={isActive}>
              <ListItemIcon open={open} selected={isActive}>
                <SvgIcon className="icon" component={item.icon} inheritViewBox fontSize="inherit" />
              </ListItemIcon>
              <ListItemText
                open={open}
                primary={item.title}
                primaryTypographyProps={{ variant: 'font16med' }}
              />
            </ListItem>
          )}
        </NavLink>
      ))}
    </List>
  );
});
