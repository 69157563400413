import { parseArticlesFromText } from '../parseArticlesFromText/parseArticlesFromText';

const readFileAsString = (file: File): Promise<string> => {
  const reader = new FileReader();

  reader.readAsText(file);

  return new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve(reader.result as string);
    };

    reader.onerror = function () {
      reject(reader.error);
    };
  });
};

export const parseArticlesFromFile = async (file: File) => {
  const text = await readFileAsString(file);

  return parseArticlesFromText(text);
};
