import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from 'app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from 'app/deprecated/store/store';
import $api from 'shared/api/api';
import omit from 'lodash/omit';
import { EmailResponse, UpdateEmailInput } from '../../types/email';

export const updateEmail = createAsyncThunk<EmailResponse, UpdateEmailInput, ThunkConfig<string>>(
  'email/updateEmail',
  async (input, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.put<EmailResponse>(`/email/${input.id}`, omit(input, 'id'));

      dispatch(
        openSnackbar({
          open: true,
          message: 'Email обновлен',
          variant: 'alert',
          alert: {
            color: 'success',
          },
        }),
      );

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при обновлении email: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
