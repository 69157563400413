import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import { SupplierItemResponse } from '../../../../../../entities/SupplierItem';

export const fetchSupplierItem = createAsyncThunk<
  SupplierItemResponse,
  string,
  ThunkConfig<string>
>('supplierItemForm/fetchSupplierItem', async (article, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const response = await $api.get<SupplierItemResponse>(`/suppliers/item/${article}`);

    return response.data;
  } catch (err) {
    const errorText = `Ошибка при загрузке товара: ${
      err instanceof Error ? err.message : String(err)
    }`;
    Sentry.captureException(err);
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
