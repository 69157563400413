import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { camelCaseArray } from '../../../../shared/lib/utils/formatting/formatResponse';
import { Job, JobResponse, JobSubtask } from '../types/job';
import { fetchJobs } from '../services/fetchJobs/fetchJobs';
import { fetchJobSubtasks } from '../services/fetchJobSubtasks/fetchJobSubtasks';

interface JobsState {
  list: Job[];
  totalCount: number;
  loading: boolean;
  jobSubtasksMap: Record<
    string,
    {
      jobSubtasks: JobSubtask[] | null;
      loading: boolean;
    }
  >;
  needReloadJobs: boolean;
}

const initialState: JobsState = {
  list: [],
  totalCount: 0,
  loading: false,
  jobSubtasksMap: {},
  needReloadJobs: false,
};

export const jobsSlice = createSlice({
  name: 'jobsSlice',
  initialState,
  reducers: {
    setNeedReloadJobs(state, action: PayloadAction<boolean>) {
      state.needReloadJobs = action.payload;
    },
    updateJobStatus(state, { payload }: PayloadAction<JobResponse>) {
      const job = state.list.find((job) => job.id === payload.id);

      if (job) {
        job.status = payload.status;
        job.result = payload.result;
        job.info = payload.info;
      }
    },
  },
  extraReducers: (builder) => {
    // fetchJobs
    builder.addCase(fetchJobs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchJobs.fulfilled, (state, action) => {
      state.loading = false;
      state.list = camelCaseArray(action.payload.data) as Job[];
      state.totalCount = action.payload.total;
    });
    builder.addCase(fetchJobs.rejected, (state) => {
      state.loading = false;
    });
    // fetchJobSubtasks
    builder.addCase(fetchJobSubtasks.pending, (state, action) => {
      const jobId = action.meta.arg;

      state.jobSubtasksMap[jobId] = {
        jobSubtasks: state.jobSubtasksMap[jobId]?.jobSubtasks,
        loading: true,
      };
    });
    builder.addCase(fetchJobSubtasks.fulfilled, (state, action) => {
      const jobId = action.meta.arg;

      state.jobSubtasksMap[jobId] = {
        jobSubtasks: camelCaseArray(action.payload.data) as JobSubtask[],
        loading: false,
      };
    });
    builder.addCase(fetchJobSubtasks.rejected, (state, action) => {
      const jobId = action.meta.arg;

      state.jobSubtasksMap[jobId] = {
        jobSubtasks: state.jobSubtasksMap[jobId]?.jobSubtasks,
        loading: false,
      };
    });
  },
});

export const { actions: jobsActions } = jobsSlice;
export const { reducer: jobsReducer } = jobsSlice;
