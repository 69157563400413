import React, { memo, useEffect, useMemo } from 'react';
import SortingTable from '../../../../shared/ui/deprecated/Table/SortingTable';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { fetchEmails } from '../../model/services/fetchEmails/fetchEmails';
import { getEmailsList, getEmailsLoading } from '../../model/selectors/emailsSelector';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import { LinearProgress, Link, Tooltip } from '@mui/material';
import MainCard from '../../../../shared/ui/deprecated/MainCard';
import ScrollX from '../../../../shared/ui/deprecated/ScrollX';
import cn from 'classnames';
import cls from './EmailsList.module.scss';
import {
  DATE_WITH_TIME_FORMAT,
  getDateString,
} from '../../../../shared/lib/utils/formatting/formatDate';
import IconButton from '../../../../shared/ui/deprecated/@extended/IconButton';
import { EditOutlined } from '@ant-design/icons';
import { getRouteUploadCatalogEmail } from '../../../../shared/const/router';

interface EmailsListProps {
  className?: string;
}

export const EmailsList = memo((props: EmailsListProps) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const emails = useAppSelector(getEmailsList);
  const loading = useAppSelector(getEmailsLoading);

  useEffect(() => {
    dispatch(fetchEmails());
  }, [dispatch]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Имя',
        accessor: 'name',
      },
      {
        Header: 'Отправитель',
        accessor: 'sender',
      },
      {
        Header: 'Тема',
        accessor: 'subject',
      },
      {
        Header: 'Имя файла',
        accessor: 'fileName',
      },
      {
        Header: 'Формат',
        accessor: 'format',
      },
      {
        Header: 'Статус',
        accessor: 'active',
        Cell: ({ value }: any) => (
          <div className={cn(cls.status, value && cls.statusActive)}>
            {value ? 'Активна' : 'Неактивна'}
          </div>
        ),
      },
      {
        Header: 'Дата обновления',
        accessor: 'updatedAt',
        Cell: ({ value }: any) => <span>{getDateString(value, DATE_WITH_TIME_FORMAT)}</span>,
      },
      {
        Header: '',
        accessor: 'edit',
        // @ts-ignore
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <Tooltip title="Просмотр и редактирование email">
            <Link href={getRouteUploadCatalogEmail(row?.original?.id)} underline="none">
              <IconButton color="primary">
                <EditOutlined />
              </IconButton>
            </Link>
          </Tooltip>
        ),
      },
    ];
  }, []);

  return (
    <MainCard content={false} className={className}>
      {loading && <LinearProgress color="primary" />}
      <ScrollX>
        <SortingTable
          columns={columns}
          data={emails}
          getHeaderProps={(column: any) => column.getSortByToggleProps()}
        />
      </ScrollX>
    </MainCard>
  );
});
