import React, { useMemo } from 'react';
import { Autocomplete as MaterialAutocomplete, TextField } from '@mui/material';
import { FormikHelpers } from 'formik';

import { SelectOption } from '../../../types/common';

export interface AutocompleteProps {
  className?: string;
  id: string;
  name?: string;
  options: SelectOption[];
  label: string;
  defaultValue?: string | number;
  value?: string | number;
  onChange?: (value: string) => void;
  error?: boolean;
  helperText?: string | boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  setFieldValue?: FormikHelpers<any>['setFieldValue'];
}

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
export const Autocomplete: React.FC<AutocompleteProps> = React.memo(
  ({
    className,
    id,
    name,
    label,
    options,
    defaultValue,
    value,
    onChange,
    error,
    helperText,
    disabled,
    size,
    setFieldValue,
  }) => {
    const optionsMap = useMemo(
      () =>
        options.reduce((map, option) => {
          map.set(option.value, option.label);
          return map;
        }, new Map()),
      [options],
    );

    const valueOption = useMemo(() => {
      if (!value) {
        return null;
      }

      return {
        value: value,
        label: optionsMap.get(value),
      };
    }, [value, optionsMap]);

    const defaultValueOption = useMemo(() => {
      if (!defaultValue) {
        return null;
      }

      return {
        value: defaultValue,
        label: optionsMap.get(defaultValue),
      };
    }, [defaultValue, optionsMap]);

    const handleOnChange = (
      event: React.SyntheticEvent<Element, Event>,
      option: SelectOption | null,
    ) => {
      const value = option?.value;

      // set formik field value
      if (name && setFieldValue) {
        setFieldValue(name, value);
      }

      onChange?.(value);
    };

    return (
      <MaterialAutocomplete
        fullWidth
        id={id}
        options={options}
        defaultValue={defaultValueOption}
        value={valueOption}
        disabled={disabled}
        className={className}
        size={size}
        renderInput={(params) => (
          <TextField {...params} label={label} error={error} helperText={helperText} size={size} />
        )}
        onChange={handleOnChange}
        noOptionsText={'Не найдено'}
      />
    );
  },
);
