import { createSlice } from '@reduxjs/toolkit';
import { camelCaseObject } from '../../../../shared/lib/utils/formatting/formatResponse';
import { SupplierOrder } from '../types/supplierOrder';
import { fetchSupplierOrder } from '../services/fetchSupplierOrder/fetchSupplierOrder';

interface SupplierOrderFormState {
  data?: SupplierOrder;
  loading: boolean;
}

const initialState: SupplierOrderFormState = {
  data: undefined,
  loading: false,
};

export const supplierOrderFormSlice = createSlice({
  name: 'supplierOrderFormSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSupplierOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSupplierOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.data = camelCaseObject(action.payload) as SupplierOrder;
    });
    builder.addCase(fetchSupplierOrder.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { actions: supplierOrderFormActions } = supplierOrderFormSlice;
export const { reducer: supplierOrderFormReducer } = supplierOrderFormSlice;
