import { AxiosResponse } from 'axios';
import $api from '../api/api';
import {
  GetSupplierCommentFileResponse,
  SupplierCommentResponse,
} from '../../entities/SupplierComment/model/types/supplierComment';
import { SuppliersCommentsResponse } from '../../pages/deprecated/SuppliersComments/model/types/suppliersCommentsTypes';
import { SupplierResponse } from '../../entities/Supplier';
import {
  FetchSuppliersInput,
  SuppliersResponse,
} from '../../pages/deprecated/Suppliers/model/types/suppliers';
import { CreateSupplierInput } from '../../pages/deprecated/Suppliers/model/types/createSupplier';
import { UpdateSupplierInput } from '../../pages/deprecated/Suppliers/model/types/updateSupplier';
import { CreateSupplierCommentInput } from '../../pages/deprecated/Suppliers/model/types/createSupplierComment';

export class SuppliersService {
  static async getSuppliers(
    input?: FetchSuppliersInput,
  ): Promise<AxiosResponse<SuppliersResponse>> {
    const params: FetchSuppliersInput = {
      sort: input?.sort ?? `["created_at", "DESC"]`,
    };

    if (input?.range) {
      params.range = input.range;
    }

    if (input?.sort) {
      params.sort = input.sort;
    }

    if (input?.filter) {
      params.filter = input.filter;
    }

    return $api.get<SuppliersResponse>('/suppliers', { params: params });
  }

  static async getSupplier(id: string): Promise<AxiosResponse<SuppliersResponse>> {
    return $api.get<SuppliersResponse>(`/supplier/${id}`);
  }

  static async getSupplierComments(id: string): Promise<AxiosResponse<SuppliersCommentsResponse>> {
    return $api.get<SuppliersCommentsResponse>(`/suppliers/comments`, {
      params: {
        sort: `["created_at", "DESC"]`,
        filter: `[{"column": "supplier_id", "op": "equal", "values": ["${id}"]}]`,
      },
    });
  }

  static async createSupplier(
    input: CreateSupplierInput,
  ): Promise<AxiosResponse<SupplierResponse>> {
    return $api.post<SupplierResponse>('/suppliers', input);
  }

  static async updateSupplier(
    id: string,
    input: UpdateSupplierInput,
  ): Promise<AxiosResponse<SupplierResponse>> {
    return $api.put<SupplierResponse>(`/supplier/${id}`, input);
  }

  static async createSupplierComment(
    input: CreateSupplierCommentInput,
  ): Promise<AxiosResponse<SupplierCommentResponse>> {
    return $api.post<SupplierCommentResponse>('/suppliers/comments', input, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static async getSupplierCommentFile(
    id: string,
  ): Promise<AxiosResponse<GetSupplierCommentFileResponse>> {
    return $api.get<GetSupplierCommentFileResponse>(`/suppliers/comment/${id}/file`, {
      responseType: 'blob',
    });
  }
}
