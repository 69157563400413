import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { camelCaseArray } from 'shared/lib/utils/formatting/formatResponse';
import { fetchLinks } from '../services/fetchLinks/fetchLinks';
import { Link } from '../types/link';

interface LinksState {
  list: Link[];
  loading: boolean;
}

const initialState: LinksState = {
  list: [],
  loading: false,
};

export const linksSlice = createSlice({
  name: 'linksSlice',
  initialState,
  reducers: {
    addLink: (state, { payload }: PayloadAction<Link>) => {
      state.list.unshift(payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLinks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLinks.fulfilled, (state, action) => {
      state.loading = false;
      state.list = camelCaseArray(action.payload.data) as Link[];
    });
    builder.addCase(fetchLinks.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { actions: linksActions } = linksSlice;
export const { reducer: linksReducer } = linksSlice;
