import { createSlice } from '@reduxjs/toolkit';
import { setSuppliersItemsDisabled } from '../services/setSuppliersItemsDisabled/setSuppliersItemsDisabled';

interface SupplierItemsState {
  loading: boolean;
}

const initialState: SupplierItemsState = {
  loading: false,
};

export const suppliersItemsDisabledSlice = createSlice({
  name: 'suppliersItemsDisabledSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSuppliersItemsDisabled.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setSuppliersItemsDisabled.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(setSuppliersItemsDisabled.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { actions: suppliersItemsDisabledActions } = suppliersItemsDisabledSlice;
export const { reducer: suppliersItemsDisabledReducer } = suppliersItemsDisabledSlice;
