import { createSlice } from '@reduxjs/toolkit';
import { makeBriefForSupplier } from '../services/makeBriefForSupplier/makeBriefForSupplier';

interface SupplierItemsState {
  loading: boolean;
}

const initialState: SupplierItemsState = {
  loading: false,
};

export const supplierBriefSlice = createSlice({
  name: 'supplierBriefSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(makeBriefForSupplier.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(makeBriefForSupplier.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(makeBriefForSupplier.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { reducer: supplierBriefReducer } = supplierBriefSlice;
