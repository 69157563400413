import { RootState } from '../../../../app/deprecated/store/store';
import { createSelector } from '@reduxjs/toolkit';
import { getSuppliersMap } from '../../../../shared/lib/utils/selectors/commonDataSelectors';

export const getSupplierOrdersList = createSelector(
  [(state: RootState) => state.supplierOrders.list, getSuppliersMap],
  (orders, suppliersMap) => {
    return orders.map((order) => ({
      ...order,
      orderNumber: `${order.rcrmNumber}-${suppliersMap?.get(order.supplierId)?.code}`,
      furnishing: order.orderData?.Meblirovka === 'true' ? 'Да' : 'Нет',
      residentialComplex: order.orderData?.Jk,
    }));
  },
);

export const getSupplierOrdersTotalCount = (state: RootState) => state.supplierOrders.totalCount;
export const getSupplierOrdersLoading = (state: RootState) => state.supplierOrders.loading;

export const getSupplierOrdersListForExcelLoading = (state: RootState) =>
  state.supplierOrders.listForExcelLoading;
