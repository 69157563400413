import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import BackLink from '../../../../shared/ui/deprecated/BackLink/BackLink';
import MainCard from '../../../../shared/ui/deprecated/MainCard';
import { UserForm } from '../../../../entities/User';
import { useNavigate } from 'react-router-dom';
import { getRouteUsers } from '../../../../shared/const/router';
import ThemeCustomization from '../../../../app/deprecated/themes';

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
export const UserCreateForm: React.FC = () => {
  const navigate = useNavigate();

  const onUserCreated = useCallback(() => {
    navigate('/users');
  }, [navigate]);

  return (
    <ThemeCustomization>
      <Stack>
        <BackLink href={getRouteUsers()} title={'К списку пользователей'} />
        <MainCard title={'Создание пользователя'}>
          <UserForm onApply={onUserCreated} />
        </MainCard>
      </Stack>
    </ThemeCustomization>
  );
};
