import { memo, useCallback } from 'react';
import { Avatar, IconButton, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { useAppSelector } from 'shared/lib/hooks/useAppSelector/useAppSelector';
import { getAuthUser } from 'features/AuthByEmail';
import { CSSObject, styled, useTheme } from '@mui/material/styles';
import { ReactComponent as LogoutIcon } from 'shared/assets/icons/redesigned/logout.svg';
import { useAppDispatch } from '../../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { logout } from '../../../../../app/deprecated/store/reducers/authSlice';

interface NavigationDrawerUserProps {
  open: boolean;
}

const LogoutButton = styled(IconButton)(
  ({ theme }): CSSObject => ({
    '& svg': {
      width: 32,
      height: 32,
    },
    '&:hover svg': {
      color: theme.palette.mediumGrayHover.main,
    },
  }),
);

export const NavigationDrawerUser = memo((props: NavigationDrawerUserProps) => {
  const { open } = props;

  const theme = useTheme();
  const user = useAppSelector(getAuthUser);
  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" p={1.5} spacing={theme.spacing(1.5)}>
        <Avatar alt={user?.name} />
        <Typography
          color={theme.palette.gray.main}
          variant={'font16med'}
          sx={{ opacity: open ? 1 : 0 }}
        >
          {user?.name}
        </Typography>
      </Stack>
      {open && (
        <Tooltip title="Выйти из аккаунта" arrow>
          <LogoutButton aria-label="logout" disableRipple onClick={handleLogout}>
            <SvgIcon component={LogoutIcon} inheritViewBox color="mediumGray" />
          </LogoutButton>
        </Tooltip>
      )}
    </Stack>
  );
});
