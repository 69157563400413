import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { camelCaseObject } from 'shared/lib/utils/formatting/formatResponse';
import { fetchLink } from '../services/fetchLink/fetchLink';
import { createLink } from '../services/createLink/createLink';
import { updateLink } from '../services/updateLink/updateLink';
import { runLink } from '../services/runLink/runLink';
import { Link } from '../types/link';

interface LinkFormState {
  data?: Link;
  loading: boolean;
  updateLoading: boolean;
  createLoading: boolean;
}

const initialState: LinkFormState = {
  data: undefined,
  loading: false,
  updateLoading: false,
  createLoading: false,
};

export const linkFormSlice = createSlice({
  name: 'linkFormSlice',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<Link>) {
      state.data = action.payload;
    },
    resetData(state) {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    // fetchLink
    builder.addCase(fetchLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLink.fulfilled, (state, action) => {
      state.loading = false;
      state.data = camelCaseObject(action.payload) as Link;
    });
    builder.addCase(fetchLink.rejected, (state) => {
      state.loading = false;
    });
    // createLink
    builder.addCase(createLink.pending, (state) => {
      state.createLoading = true;
    });
    builder.addCase(createLink.fulfilled, (state) => {
      state.createLoading = false;
    });
    builder.addCase(createLink.rejected, (state) => {
      state.createLoading = false;
    });
    // updateLink
    builder.addCase(updateLink.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateLink.fulfilled, (state) => {
      state.updateLoading = false;
    });
    builder.addCase(updateLink.rejected, (state) => {
      state.updateLoading = false;
    });
    // runLink
    builder.addCase(runLink.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(runLink.fulfilled, (state, action) => {
      state.updateLoading = false;
      state.data = camelCaseObject(action.payload) as Link;
    });
    builder.addCase(runLink.rejected, (state) => {
      state.updateLoading = false;
    });
  },
});

export const { actions: linkFormActions } = linkFormSlice;
export const { reducer: linkFormReducer } = linkFormSlice;
