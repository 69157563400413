import { createSlice } from '@reduxjs/toolkit';
import { camelCaseObject } from '../../../../../shared/lib/utils/formatting/formatResponse';
import { Supplier } from '../../../../../entities/Supplier';
import { fetchSupplier } from '../services/fetchSupplier/fetchSupplier';
import { createSupplier } from '../services/createSupplier/createSupplier';
import { updateSupplier } from '../services/updateSupplier/updateSupplier';

interface SupplierFormState {
  data?: Supplier;
  loading: boolean;
  updateLoading: boolean;
  createLoading: boolean;
}

const initialState: SupplierFormState = {
  data: undefined,
  loading: false,
  updateLoading: false,
  createLoading: false,
};

export const supplierFormSlice = createSlice({
  name: 'supplierFormSlice',
  initialState,
  reducers: {
    resetData(state) {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    // fetchSupplier
    builder.addCase(fetchSupplier.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSupplier.fulfilled, (state, action) => {
      state.loading = false;
      state.data = camelCaseObject(action.payload) as Supplier;
    });
    builder.addCase(fetchSupplier.rejected, (state) => {
      state.loading = false;
    });
    // createSupplier
    builder.addCase(createSupplier.pending, (state) => {
      state.createLoading = true;
    });
    builder.addCase(createSupplier.fulfilled, (state) => {
      state.createLoading = false;
    });
    builder.addCase(createSupplier.rejected, (state) => {
      state.createLoading = false;
    });
    // updateSupplier
    builder.addCase(updateSupplier.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateSupplier.fulfilled, (state) => {
      state.updateLoading = false;
    });
    builder.addCase(updateSupplier.rejected, (state) => {
      state.updateLoading = false;
    });
  },
});

export const { actions: supplierFormActions } = supplierFormSlice;
export const { reducer: supplierFormReducer } = supplierFormSlice;
