import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import { JobResponse, jobsActions } from '../../../../../../entities/Job';

export const stopJob = createAsyncThunk<JobResponse, string, ThunkConfig<string>>(
  'jobs/stopJob',
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.post(`/job/${id}/stop`);

      dispatch(
        openSnackbar({
          open: true,
          message: 'Загрузка остановлена',
          variant: 'alert',
          alert: {
            color: 'success',
          },
        }),
      );

      dispatch(jobsActions.updateJobStatus(response.data));

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при остановке загрузки: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
