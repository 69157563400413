import { memo, useState } from 'react';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { NavigationDrawerHeader } from '../NavigationDrawerHeader/NavigationDrawerHeader';
import { NavigationDrawerUser } from '../NavigationDrawerUser/NavigationDrawerUser';
import { NavigationDrawerList } from '../NavigationDrawerList/NavigationDrawerList';
import { NAVIGATION_DRAWER_STATE } from '../../../../../shared/const/localStorage';

enum NavigationDrawerState {
  'Opened' = 'opened',
  'Closed' = 'closed',
}

const drawerWidth = 218;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(8),
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }): CSSObject => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      justifyContent: 'space-between',
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const NavigationDrawer = memo(() => {
  const [open, setOpen] = useState(
    localStorage.getItem(NAVIGATION_DRAWER_STATE) !== NavigationDrawerState.Closed,
  );

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem(NAVIGATION_DRAWER_STATE, NavigationDrawerState.Opened);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem(NAVIGATION_DRAWER_STATE, NavigationDrawerState.Closed);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <NavigationDrawerHeader
        open={open}
        onDrawerOpen={handleDrawerOpen}
        onDrawerClose={handleDrawerClose}
      />
      <NavigationDrawerList open={open} />
      <NavigationDrawerUser open={open} />
    </Drawer>
  );
});
