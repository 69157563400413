import { DAYS, SchedulePeriodEnum } from './constants';
import { Schedule } from '../SelectCronSchedule';

export const getCroneValue = (schedule: Schedule): string | null => {
  if (schedule.period === SchedulePeriodEnum.Monthly) {
    return `${schedule.minute} ${schedule.hour} ${schedule.day} * *`;
  }

  if (schedule.period === SchedulePeriodEnum.Weekly) {
    return `${schedule.minute} ${schedule.hour} * * ${schedule.dayOfWeek}`;
  }

  if (schedule.period === SchedulePeriodEnum.Daily) {
    return `${schedule.minute} ${schedule.hour} * * *`;
  }

  if (schedule.period === SchedulePeriodEnum.SeveralTimesADay) {
    return `0 */${schedule.hoursInterval} * * *`;
  }

  if (schedule.period === SchedulePeriodEnum.SeveralTimesAHour) {
    return `*/${schedule.minutesInterval} * * * *`;
  }

  return null;
};

export const convertCronToSchedule = (croneValue: string): Schedule => {
  const schedule: Schedule = {
    period: '',
    day: 1,
    dayOfWeek: 1,
    hour: 0,
    minute: 0,
    hoursInterval: 1,
    minutesInterval: 10,
  };

  const period = croneValue.split(' ');

  if (period[0].includes('/')) {
    schedule.period = SchedulePeriodEnum.SeveralTimesAHour;
    schedule.minutesInterval = +period[0].substring(period[0].indexOf('/') + 1);
    return schedule;
  }

  if (period[1].includes('/')) {
    schedule.period = SchedulePeriodEnum.SeveralTimesADay;
    schedule.hoursInterval = +period[1].substring(period[1].indexOf('/') + 1);
    schedule.minutesInterval = +period[0];
    return schedule;
  }

  if (period[0] !== '*' && period[1] !== '*') {
    schedule.period = SchedulePeriodEnum.Daily;
    schedule.minute = +period[0];
    schedule.hour = +period[1];
  }

  if (period[2] !== '*') {
    schedule.period = SchedulePeriodEnum.Monthly;
    schedule.day = +period[2];
  }

  if (period[4] !== '*') {
    schedule.period = SchedulePeriodEnum.Weekly;
    schedule.dayOfWeek = +period[4];
  }

  return schedule;
};

export const convertCronToString = (croneValue: string) => {
  const schedule = convertCronToSchedule(croneValue);

  if (schedule.period === SchedulePeriodEnum.Monthly) {
    return `Ежемесячно ${schedule.day} числа в ${schedule.hour
      .toString()
      .padStart(2, '0')}:${schedule.minute.toString().padStart(2, '0')}`;
  }

  if (schedule.period === SchedulePeriodEnum.Weekly) {
    return `Еженедельно по ${DAYS[schedule.dayOfWeek - 1]} в ${schedule.hour
      .toString()
      .padStart(2, '0')}:${schedule.minute.toString().padStart(2, '0')}`;
  }

  if (schedule.period === SchedulePeriodEnum.Daily) {
    return `Еженедневно в ${schedule.hour.toString().padStart(2, '0')}:${schedule.minute
      .toString()
      .padStart(2, '0')}`;
  }

  if (schedule.period === SchedulePeriodEnum.SeveralTimesADay) {
    if (schedule.hoursInterval === 1) {
      return 'Каждый час';
    }
    return `Каждые ${schedule.hoursInterval} часа`;
  }

  if (schedule.period === SchedulePeriodEnum.SeveralTimesAHour) {
    return `Каждые ${schedule.minutesInterval} минут`;
  }

  return '';
};
