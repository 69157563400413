import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

interface AlertDialogProps {
  open: boolean;
  confirmText: string;
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonText?: string;
  closeButtonText?: string;
}
/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
const AlertDialog: React.FC<AlertDialogProps> = (props) => {
  const {
    open,
    confirmText,
    onClose,
    onConfirm,
    confirmButtonText = 'Да',
    closeButtonText = 'Отменить',
  } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>{confirmText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {closeButtonText}
        </Button>
        <Button onClick={onConfirm} variant="contained" color="error" autoFocus>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
