// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobSubtasksTable_status__kxGNP {\n  padding: 1px 8px;\n  font-size: 12px;\n  line-height: 20px;\n  border-radius: 4px;\n  text-align: center;\n}\n\n.JobSubtasksTable_statusCompleted__1G0ug {\n  color: #52C41A;\n  background-color: #F6FFED;\n}\n\n.JobSubtasksTable_statusWarning__126fS {\n  color: #E59D14;\n  background-color: #FFF7E6;\n}\n\n.JobSubtasksTable_statusError__3yC2q {\n  color: #FF4D4F;\n  background-color: #FFF1F0;\n}", "",{"version":3,"sources":["webpack://src/entities/Job/ui/JobSubtasksTable/JobSubtasksTable.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF","sourcesContent":[".status {\n  padding: 1px 8px;\n  font-size: 12px;\n  line-height: 20px;\n  border-radius: 4px;\n  text-align: center;\n}\n\n.statusCompleted {\n  color: #52C41A;\n  background-color: #F6FFED;\n}\n\n.statusWarning {\n  color: #E59D14;\n  background-color: #FFF7E6;\n}\n\n.statusError {\n  color: #FF4D4F;\n  background-color: #FFF1F0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "JobSubtasksTable_status__kxGNP",
	"statusCompleted": "JobSubtasksTable_statusCompleted__1G0ug",
	"statusWarning": "JobSubtasksTable_statusWarning__126fS",
	"statusError": "JobSubtasksTable_statusError__3yC2q"
};
export default ___CSS_LOADER_EXPORT___;
