import { createSlice } from '@reduxjs/toolkit';
import { createJobFromFile } from '../services/createJobFromFile/createJobFromFile';

interface CreateJobFromFileState {
  loading: boolean;
}

const initialState: CreateJobFromFileState = {
  loading: false,
};

export const createJobFromFileSlice = createSlice({
  name: 'createJobFromFileSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createJobFromFile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createJobFromFile.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createJobFromFile.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { reducer: createJobFromFileReducer } = createJobFromFileSlice;
