import React, { memo, useEffect } from 'react';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import { getJobsLoading } from '../../model/selectors/jobsSelectors';
import { fetchJob } from '../../model/services/fetchJob/fetchJob';
import { getJobData } from '../../model/selectors/jobSelectors';
import { Grid, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { getSuppliersMap } from '../../../../shared/lib/utils/selectors/commonDataSelectors';
import MainCard from '../../../../shared/ui/deprecated/MainCard';
import { getDateString } from '../../../../shared/lib/utils/formatting/formatDate';
import { JobSubtasksTable } from '../JobSubtasksTable/JobSubtasksTable';
import { DownloadOutlined } from '@ant-design/icons';
import { JobCreator } from '../JobCreator/JobCreator';
import { JobContent } from '../JobContent/JobContent';
import { useDownloadFile } from '../../../../shared/lib/hooks/useDownloadFile/useDownloadFile';
import { LoadingButton } from '@mui/lab';

interface JobDetailsProps {
  className?: string;
  jobId: string;
}

export const JobDetails = memo((props: JobDetailsProps) => {
  const { className, jobId } = props;

  const dispatch = useAppDispatch();
  const job = useAppSelector(getJobData);
  const loading = useAppSelector(getJobsLoading);
  const suppliersMap = useAppSelector(getSuppliersMap);
  const [handleDownloadFullFile, fullFileLoading] = useDownloadFile();
  const [handleDownloadJobFile, jobFileLoading] = useDownloadFile();
  const [handleDownloadSubtasksFile, subtasksFileLoading] = useDownloadFile();

  useEffect(() => {
    dispatch(fetchJob(jobId));
  }, [dispatch, jobId]);

  if (loading) {
    return <LinearProgress color="primary" />;
  }

  if (!job) {
    return null;
  }

  return (
    <Stack gap={2}>
      <MainCard
        title="Данные загрузки"
        secondary={
          <Stack direction="row" gap={2}>
            {!!job.subtasksCount && (
              <LoadingButton
                variant="outlined"
                startIcon={<DownloadOutlined />}
                onClick={handleDownloadFullFile(`/job/${jobId}/job_full_file`)}
                loading={fullFileLoading}
              >
                {'Скачать полный архив'}
              </LoadingButton>
            )}
            <LoadingButton
              variant="outlined"
              startIcon={<DownloadOutlined />}
              onClick={handleDownloadJobFile(`/job/${jobId}/job_file`)}
              loading={jobFileLoading}
            >
              {'Скачать файл загрузки'}
            </LoadingButton>
          </Stack>
        }
      >
        <Grid spacing={2} className={className} container>
          <Grid item xs={12}>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography>{'Создатель загрузки:'}</Typography>
              <JobCreator creator={job.creator} creatorId={job.creatorId} />
              <TextField
                sx={{ width: 270 }}
                variant="standard"
                defaultValue={job.creatorId}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography>{'Контент:'}</Typography>
              <JobContent contentType={job.contentTypes[0]} />
            </Stack>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="ID задачи"
              defaultValue={job.id}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="ID поставщика"
              defaultValue={job.supplierId}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Поставщик"
              defaultValue={suppliersMap?.get(job.supplierId)?.name ?? ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Создана"
              defaultValue={getDateString(job.createdAt)}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Обновлена"
              defaultValue={getDateString(job.updatedAt)}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Имя файла"
              defaultValue={job.fileName}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Информация"
              defaultValue={job.info}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Статус"
              defaultValue={job.status}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Результат"
              defaultValue={job.result}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Конвертер статус"
              defaultValue={job.converterStatus}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Конвертер Id"
              defaultValue={job.converterId}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Импортер статус"
              defaultValue={job.importerStatus}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Импортер Id"
              defaultValue={job.importerId}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Постимпортер статус"
              defaultValue={job.postImporterStatus}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Постимпортер Id"
              defaultValue={job.postImporterId}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Количество подзадач"
              defaultValue={job.subtasksCount}
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </MainCard>
      <MainCard
        title={'Подзадачи'}
        secondary={
          !!job.subtasksCount && (
            <LoadingButton
              variant="outlined"
              startIcon={<DownloadOutlined />}
              onClick={handleDownloadSubtasksFile(`/job/${jobId}/job_subtasks_file`)}
              loading={subtasksFileLoading}
            >
              {'Скачать файл подзадач'}
            </LoadingButton>
          )
        }
      >
        <JobSubtasksTable jobId={jobId} />
      </MainCard>
    </Stack>
  );
});
