import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createJobFromLinkData } from '../services/createJobFromLinkData/createJobFromLinkData';
import { CreateJobFromLinkDataFormValues } from '../types/createJobFromLinkData';

interface CreateJobFromLinkDataState {
  loading: boolean;
  formInitialValues: CreateJobFromLinkDataFormValues | null;
}

const initialState: CreateJobFromLinkDataState = {
  loading: false,
  formInitialValues: null,
};

export const createJobFromLinkDataSlice = createSlice({
  name: 'createJobFromLinkDataSlice',
  initialState,
  reducers: {
    setFormInitialData(state, { payload }: PayloadAction<CreateJobFromLinkDataFormValues>) {
      state.formInitialValues = payload;
    },
    clearFormInitialData(state) {
      state.formInitialValues = initialState.formInitialValues;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createJobFromLinkData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createJobFromLinkData.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createJobFromLinkData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { actions: createJobFromLinkDataActions } = createJobFromLinkDataSlice;
export const { reducer: createJobFromLinkDataReducer } = createJobFromLinkDataSlice;
