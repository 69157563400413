export enum RolesEnum {
  Admin = 'administrator',
  Owner = 'owner',
  Worker = 'worker',
  Viewer = 'viewer',
  Candidate = 'candidate',
}

export const RolesRuMap = {
  [RolesEnum.Admin]: 'Admin',
  [RolesEnum.Owner]: 'Owner',
  [RolesEnum.Worker]: 'Worker',
  [RolesEnum.Viewer]: 'Viewer',
  [RolesEnum.Candidate]: 'Candidate',
};

export const rolesOptions = Object.values(RolesEnum).map((item) => ({
  value: item,
  label: RolesRuMap[item],
}));

export const yesNoOptions = [
  { value: 'true', label: 'Да' },
  { value: 'false', label: 'Нет' },
];
