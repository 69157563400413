// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmailsList_status__mu7RE {\n  padding: 1px 8px;\n  font-size: 12px;\n  line-height: 20px;\n  border-radius: 4px;\n  text-align: center;\n  color: #8C8C8C;\n  background-color: #FAFAFA;\n}\n\n.EmailsList_statusActive__2gtPd {\n  color: #52C41A;\n  background-color: #F6FFED;\n}", "",{"version":3,"sources":["webpack://src/entities/Email/ui/EmailsList/EmailsList.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF","sourcesContent":[".status {\n  padding: 1px 8px;\n  font-size: 12px;\n  line-height: 20px;\n  border-radius: 4px;\n  text-align: center;\n  color: #8C8C8C;\n  background-color: #FAFAFA;\n}\n\n.statusActive {\n  color: #52C41A;\n  background-color: #F6FFED;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "EmailsList_status__mu7RE",
	"statusActive": "EmailsList_statusActive__2gtPd"
};
export default ___CSS_LOADER_EXPORT___;
