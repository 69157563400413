import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  getSupplierOrderFormData,
  getSupplierOrderFormLoading,
} from '../../model/selectors/supplierOrderFormSelectors';
import { LinearProgress, Stack } from '@mui/material';
import { fetchSupplierOrder } from '../../model/services/fetchSupplierOrder/fetchSupplierOrder';
import { fetchSupplierOrderItems } from '../../../SupplierOrderItem';
import { getSupplierOrderItemsLoading } from '../../../SupplierOrderItem';
import cls from './SupplierOrderForm.module.scss';
import { StickyContentLayout } from '../../../../shared/layouts/StickyContentLayout';
import { SupplierOrderFormHeader } from '../SupplierOrderFormHeader/SupplierOrderFormHeader';
import { SupplierOrderItems } from '../SupplierOrderItems/SupplierOrderItems';
import { SupplierOrderInfo } from '../SupplierOrderInfo/SupplierOrderInfo';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';

interface SupplierOrderFormProps {
  className?: string;
}

export const SupplierOrderForm = (props: SupplierOrderFormProps) => {
  const { className } = props;
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const formData = useAppSelector(getSupplierOrderFormData);
  const orderLoading = useAppSelector(getSupplierOrderFormLoading);
  const orderItemsLoading = useAppSelector(getSupplierOrderItemsLoading);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(fetchSupplierOrder(id));
    dispatch(fetchSupplierOrderItems(id));
  }, [dispatch, id]);

  if (orderLoading || orderItemsLoading) {
    return <LinearProgress color="primary" />;
  }

  if (!formData) {
    return <div>Нет данных о заказе</div>;
  }

  return (
    <Stack className={className} gap={2}>
      <SupplierOrderFormHeader />
      <StickyContentLayout
        content={<SupplierOrderItems />}
        right={<SupplierOrderInfo className={cls.right} />}
      />
    </Stack>
  );
};
