import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'shared/lib/hooks/deprecated/useAuth';

// types
import { GuardProps } from 'shared/types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!auth.isLoggedIn) {
      navigate('/login', { replace: true, state: { from: location } });
    }
  }, [auth.isLoggedIn, navigate, location]);

  return children;
};

export default AuthGuard;
