import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from 'app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from 'app/deprecated/store/store';
import $api from 'shared/api/api';
import { EmailsResponse } from '../../types/emails';

export const fetchEmails = createAsyncThunk<EmailsResponse, void, ThunkConfig<string>>(
  'email/fetchEmails',
  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.get<EmailsResponse>('/emails');

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при загрузке emails: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
