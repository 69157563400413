import React, { memo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import { FormControl, InputLabel } from '@mui/material';

import { SelectOption } from '../../../types/common';

export interface SelectProps extends OutlinedTextFieldProps {
  id: string;
  options: SelectOption[];
  label: string;
  className?: string;
}

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
export const Select: React.FC<SelectProps> = memo((props: SelectProps) => {
  const { id, options, label, className, value, error, disabled, ...otherProps } = props;

  const optionsData = React.useMemo(
    () =>
      options.map(({ label, value, isDisabled }) => (
        <MenuItem key={value} value={value} disabled={isDisabled}>
          {label}
        </MenuItem>
      )),
    [options],
  );

  return (
    <FormControl variant="outlined" className={className} fullWidth>
      <InputLabel htmlFor={id} shrink={!!`${value}`} error={error} disabled={disabled}>
        {label}
      </InputLabel>
      <TextField
        id={id}
        select
        fullWidth
        error={error}
        value={value}
        disabled={disabled}
        {...otherProps}
      >
        <MenuItem value={''}>
          <em>Не выбрано</em>
        </MenuItem>
        {optionsData}
      </TextField>
    </FormControl>
  );
});
