import { memo } from 'react';
import { Stack, SvgIcon } from '@mui/material';
import { ReactComponent as AppLogo } from 'shared/assets/icons/redesigned/app-logo.svg';
import { ReactComponent as MenuIcon } from 'shared/assets/icons/redesigned/menu.svg';
import { ReactComponent as MenuExpandedIcon } from 'shared/assets/icons/redesigned/menu-expanded.svg';
import { CSSObject, styled, useTheme } from '@mui/material/styles';
import { MenuButton } from '../MenuButton/MenuButton';

interface NavigationDrawerHeaderProps {
  open: boolean;
  onDrawerOpen: () => void;
  onDrawerClose: () => void;
}

const DrawerHeader = styled('div')(
  ({ theme }): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  }),
);

export const NavigationDrawerHeader = memo((props: NavigationDrawerHeaderProps) => {
  const { open, onDrawerOpen, onDrawerClose } = props;

  const theme = useTheme();

  return (
    <DrawerHeader>
      {open ? (
        <Stack direction="row" alignItems="center" spacing={theme.spacing(1.25)}>
          <MenuButton
            icon={
              <SvgIcon
                component={MenuExpandedIcon}
                inheritViewBox
                fontSize="inherit"
                color="blue"
              />
            }
            onClick={onDrawerClose}
          />
          <AppLogo />
        </Stack>
      ) : (
        <MenuButton
          icon={<SvgIcon component={MenuIcon} inheritViewBox fontSize="inherit" color="black" />}
          onClick={onDrawerOpen}
        />
      )}
    </DrawerHeader>
  );
});
