import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../../../entities/User';

interface AuthState {
  isLoggedIn: boolean;
  isInitialized: boolean;
  user: User | null;
}

const initialState: AuthState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    register(state, { payload }: PayloadAction<AuthState['user']>) {
      state.user = payload;
    },
    login(state, { payload }: PayloadAction<AuthState['user']>) {
      state.user = payload;
      state.isLoggedIn = true;
      state.isInitialized = true;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.isInitialized = true;
      state.user = null;
    },
  },
});

export default authSlice.reducer;

export const { register, login, logout } = authSlice.actions;
