import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import config from 'app/config';
import useAuth from 'shared/lib/hooks/deprecated/useAuth';

// types
import { GuardProps } from 'shared/types/auth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // @ts-ignore
  const path = location.state?.from ?? config.defaultPath;

  useEffect(() => {
    if (isLoggedIn) {
      navigate(path, { replace: true });
    }
  }, [path, isLoggedIn, navigate]);

  return children;
};

export default GuestGuard;
