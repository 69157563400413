import React, { memo, useCallback } from 'react';
import LoadingButton from '../../../../shared/ui/deprecated/@extended/LoadingButton';
import { getSupplierBriefLoading } from '../model/selectors/supplierBriefSelectors';
import { makeBriefForSupplier } from '../model/services/makeBriefForSupplier/makeBriefForSupplier';
import AlertDialog from '../../../../shared/ui/deprecated/Modal/AlertDialog';
import { useModal } from '../../../../shared/lib/hooks/useModal/useModal';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';

interface SupplierBriefButtonProps {
  className?: string;
  supplierId: string;
  replace?: boolean;
}

export const SupplierBriefButton = memo((props: SupplierBriefButtonProps) => {
  const { className, supplierId, replace } = props;

  const loading = useAppSelector(getSupplierBriefLoading);
  const dispatch = useAppDispatch();

  const handleMakeBrief = useCallback(() => {
    dispatch(makeBriefForSupplier({ supplierId, replace }));
  }, [dispatch, supplierId, replace]);

  const { open, onOpen, onClose, onConfirm } = useModal({ confirmCallback: handleMakeBrief });

  return (
    <>
      <AlertDialog
        open={open}
        confirmText={'Вы действительно хотите запустить обновление?'}
        onClose={onClose}
        onConfirm={onConfirm}
        confirmButtonText={'Да'}
      />
      <LoadingButton className={className} variant="contained" onClick={onOpen} loading={loading}>
        {replace ? 'Обновить все данные по товарам' : 'Обновить остатки и цены'}
      </LoadingButton>
    </>
  );
});
