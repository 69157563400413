import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import { JobResponse, jobsActions } from '../../../../../../entities/Job';
import { CreateJobInput } from '../../types/createJobFromFile';

export const createJobFromFile = createAsyncThunk<JobResponse, CreateJobInput, ThunkConfig<string>>(
  'jobs/createJobFromFile',
  async (input, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.post<JobResponse>('/jobs', input, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      dispatch(
        openSnackbar({
          open: true,
          message: 'Загрузка создана',
          variant: 'alert',
          alert: {
            color: 'success',
          },
        }),
      );

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при создани загрузки: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    } finally {
      dispatch(jobsActions.setNeedReloadJobs(true));
    }
  },
);
