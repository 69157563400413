export enum AuthTypeEnum {
  None = 'none',
  Basic = 'basic',
  Header = 'header',
  Ftp = 'ftp',
}

export const authTypeOptions = [
  {
    value: AuthTypeEnum.None,
    label: 'http (без пароля)',
  },
  {
    value: AuthTypeEnum.Basic,
    label: 'http (по логину и паролю)',
  },
  {
    value: AuthTypeEnum.Header,
    label: 'http (по заголовку)',
  },
  {
    value: AuthTypeEnum.Ftp,
    label: 'ftp',
  },
];
