// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SupplierOrderInfo_priceItem__2QOyX {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n\n.SupplierOrderInfo_priceItemTitle__1dt5k {\n  color: #8C8C8C;\n}", "",{"version":3,"sources":["webpack://src/entities/SupplierOrder/ui/SupplierOrderInfo/SupplierOrderInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".priceItem {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n\n.priceItemTitle {\n  color: #8C8C8C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priceItem": "SupplierOrderInfo_priceItem__2QOyX",
	"priceItemTitle": "SupplierOrderInfo_priceItemTitle__1dt5k"
};
export default ___CSS_LOADER_EXPORT___;
