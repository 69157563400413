import React, { memo } from 'react';
import { CreatorEnum } from '../../model/lib/jobsConstants';
import { Link, Tooltip } from '@mui/material';
import { LinkOutlined, UserOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import { getUsersMap } from '../../../../shared/lib/utils/selectors/commonDataSelectors';
import { getRouteUploadCatalogLink } from '../../../../shared/const/router';

interface JobCreatorProps {
  creator: string;
  creatorId: string;
}

export const JobCreator = memo((props: JobCreatorProps) => {
  const { creator, creatorId } = props;

  const usersMap = useAppSelector(getUsersMap);

  if (creator === CreatorEnum.Link) {
    return (
      <Tooltip title={'Ссылка'}>
        <Link href={getRouteUploadCatalogLink(creatorId)}>{<LinkOutlined />}</Link>
      </Tooltip>
    );
  }

  if (creator === CreatorEnum.User) {
    const user = usersMap.get(creatorId);

    return (
      <Tooltip
        title={
          user ? (
            <div>
              <div>{user.name}</div>
              <div>{user.email}</div>
            </div>
          ) : (
            'Пользователь'
          )
        }
      >
        <UserOutlined />
      </Tooltip>
    );
  }

  return null;
});
