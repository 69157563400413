import React from 'react';
import { Link } from '@mui/material';
import { LeftOutlined } from '@ant-design/icons';

import styles from './BackLink.module.scss';

interface BackLinkProps {
  href: string;
  title: string;
}

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
const BackLink: React.FC<BackLinkProps> = (props) => {
  const { href, title } = props;

  return (
    <Link href={href} className={styles.backLink}>
      <LeftOutlined className={styles.backLinkIcon} />
      <span>{title}</span>
    </Link>
  );
};

export default BackLink;
