import { AxiosResponse } from 'axios';
import $api from '../api/api';
import { AuthResponse } from '../types/auth/AuthApiTypes';

export class AuthService {
  static async auth(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/auth', { email, password });
  }

  static async signup(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/signup', { email, password });
  }
}
