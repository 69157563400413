import { createSlice } from '@reduxjs/toolkit';
import { camelCaseArray } from '../../../../shared/lib/utils/formatting/formatResponse';
import { SupplierOrderItem } from '../types/supplierOrderItem';
import { fetchSupplierOrdersItemsByOrder } from '../services/fetchSupplierOrdersItemsByOrder/fetchSupplierOrdersItemsByOrder';
import { SupplierOrder } from '../../../SupplierOrder/model/types/supplierOrder';

interface SupplierOrdersItemsByOrderState {
  orders: SupplierOrder[];
  orderItems: SupplierOrderItem[];
  loading: boolean;
}

const initialState: SupplierOrdersItemsByOrderState = {
  orders: [],
  orderItems: [],
  loading: false,
};

export const supplierOrdersItemsByOrderSlice = createSlice({
  name: 'supplierOrdersItemsByOrderSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSupplierOrdersItemsByOrder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchSupplierOrdersItemsByOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = camelCaseArray(action.payload.order.data) as SupplierOrder[];
      state.orderItems = camelCaseArray(action.payload.orderItems.data) as SupplierOrderItem[];
    });
    builder.addCase(fetchSupplierOrdersItemsByOrder.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reducer: supplierOrdersItemsByOrderReducer } = supplierOrdersItemsByOrderSlice;
