import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'simplebar/src/simplebar.css';
import 'shared/assets/third-party/apex-chart.css';
import 'shared/assets/third-party/react-table.css';
import App from './app/App';
import { store } from 'app/deprecated/store/store';
import { ConfigProvider } from 'app/deprecated/contexts/ConfigContext';
import './app/redesigned/styles/index.scss';
import { ThemeProvider } from './app/redesigned/providers/ThemeProvider';

ReactDOM.render(
  <ReduxProvider store={store}>
    <ConfigProvider>
      <BrowserRouter>
        <ThemeProvider>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>,
  document.getElementById('root'),
);
