import { createSlice } from '@reduxjs/toolkit';
import { camelCaseArray } from '../../../../shared/lib/utils/formatting/formatResponse';
import { fetchSupplierOrders } from '../services/fetchSupplierOrders/fetchSupplierOrders';
import { SupplierOrder } from '../types/supplierOrder';
import { loadToExcel } from '../../../../shared/ui/deprecated/ExcelExportButton';

interface SupplierOrdersState {
  list: SupplierOrder[];
  totalCount: number;
  loading: boolean;
  listForExcelLoading: boolean;
}

const initialState: SupplierOrdersState = {
  list: [],
  totalCount: 0,
  loading: false,
  listForExcelLoading: false,
};

export const supplierOrdersSlice = createSlice({
  name: 'supplierOrdersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSupplierOrders.pending, (state, action) => {
      if (action.meta.arg?.forExcel) {
        state.listForExcelLoading = true;
        return;
      }
      state.loading = true;
    });
    builder.addCase(fetchSupplierOrders.fulfilled, (state, action) => {
      if (action.meta.arg?.forExcel) {
        loadToExcel(action.payload.data, 'supplierOrders');
        state.listForExcelLoading = false;
        return;
      }
      state.loading = false;
      state.list = camelCaseArray(action.payload.data) as SupplierOrder[];
      state.totalCount = action.payload.total;
    });
    builder.addCase(fetchSupplierOrders.rejected, (state, action) => {
      if (action.meta.arg?.forExcel) {
        state.listForExcelLoading = false;
        return;
      }
      state.loading = false;
    });
  },
});

export const { actions: supplierOrdersActions } = supplierOrdersSlice;
export const { reducer: supplierOrdersReducer } = supplierOrdersSlice;
