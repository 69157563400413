import { configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import { combineReducers } from 'redux';
import authReducer from './reducers/authSlice';
import commonDataReducer from './reducers/commonDataSlice';
import menuReducer from './reducers/menuSlice';
import snackbarReducer, { openSnackbar } from './reducers/snackbarSlice';
import {
  supplierCommentsReducer,
  supplierFormReducer,
  suppliersReducer,
} from '../../../pages/deprecated/Suppliers';
import { suppliersCommentsReducer } from '../../../pages/deprecated/SuppliersComments';
import { usersReducer } from '../../../pages/deprecated/Users';
import { supplierItemsReducer } from '../../../pages/deprecated/SupplierItems';
import { linkFormReducer, linksReducer } from '../../../entities/Link';
import { supplierItemFormReducer } from '../../../pages/deprecated/SupplierItemForm';
import { suppliersItemsDisabledReducer } from '../../../features/deprecated/DisableSupplierItems';
import { supplierBriefReducer } from '../../../features/deprecated/SupplierBriefButton';
import { createJobFromFileReducer } from '../../../features/deprecated/CreateJobFromFileForm';
import { createJobFromLinkDataReducer } from '../../../features/deprecated/CreateJobFromLinkDataForm';
import { jobReducer, jobsReducer } from '../../../entities/Job';
import { stopJobReducer } from '../../../features/deprecated/StopJobButton';
import { supplierOrderFormReducer, supplierOrdersReducer } from '../../../entities/SupplierOrder';
import {
  supplierOrderItemsReducer,
  supplierOrdersItemsByOrderReducer,
} from '../../../entities/SupplierOrderItem';
import { initApiInterceptors } from '../../../shared/api/api';
import { emailFormReducer, emailsReducer } from '../../../entities/Email';
import { userFormReducer } from '../../../entities/User';
import { rtkApi } from '../../../shared/api/rtkApi';

const rootReducer = combineReducers({
  [rtkApi.reducerPath]: rtkApi.reducer,
  auth: authReducer,
  commonData: commonDataReducer,
  menu: menuReducer,
  snackbar: snackbarReducer,
  job: jobReducer,
  jobs: jobsReducer,
  suppliers: suppliersReducer,
  supplierItems: supplierItemsReducer,
  supplierItemForm: supplierItemFormReducer,
  suppliersItemsDisabled: suppliersItemsDisabledReducer,
  suppliersComments: suppliersCommentsReducer,
  supplierForm: supplierFormReducer,
  supplierBrief: supplierBriefReducer,
  supplierComments: supplierCommentsReducer,
  supplierOrders: supplierOrdersReducer,
  supplierOrderItems: supplierOrderItemsReducer,
  supplierOrderForm: supplierOrderFormReducer,
  supplierOrdersItemsByOrder: supplierOrdersItemsByOrderReducer,
  users: usersReducer,
  links: linksReducer,
  linkForm: linkFormReducer,
  createJobFromFile: createJobFromFileReducer,
  createJobFromLinkData: createJobFromLinkDataReducer,
  stopJob: stopJobReducer,
  emails: emailsReducer,
  emailForm: emailFormReducer,
  userForm: userFormReducer,
});

const middlewares: Middleware[] = [];

// if (process.env.REACT_APP_DEBUG === 'true') {
//   middlewares.push(logger);
// }

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const message = action?.payload?.data?.message;

    console.warn('RTK Query error:', action);

    if (message) {
      api.dispatch(
        openSnackbar({
          open: true,
          message,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );
    }
  }

  return next(action);
};

middlewares.push(rtkApi.middleware);
middlewares.push(rtkQueryErrorLogger);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});
initApiInterceptors(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export interface ThunkConfig<T> {
  rejectValue: T;
  state: RootState;
}
