import React, { memo, useMemo } from 'react';
import { FormControlLabel, FormHelperText, Grid, Stack, Switch, TextField } from '@mui/material';
import { Autocomplete } from '../../../../../shared/ui/deprecated/Autocomplete/Autocomplete';
import LoadingButton from '../../../../../shared/ui/deprecated/@extended/LoadingButton';
import { Formik, FormikHelpers } from 'formik';
import { useAppSelector } from '../../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import { getSuppliersOptions } from '../../../../../shared/lib/utils/selectors/commonDataSelectors';
import * as yup from 'yup';
import {
  ArticlesType,
  articlesTypeOptions,
  InputType,
  inputTypeOptions,
} from '../../model/consts/consts';
import UploadSingleFile from '../../../../../shared/ui/deprecated/third-party/dropzone/SingleFile';
import { setSupplierItemsDisabledFromInput } from '../../model/services/setSupplierItemsDisabledFromInput/setSupplierItemsDisabledFromInput';
import { useAppDispatch } from '../../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';

interface DisableSupplierItemsFormProps {
  className?: string;
}

interface DisableSupplierItemsFormValues {
  supplierId: string;
  articlesType: string;
  inputType: string;
  comment: string;
  recovery: boolean;
  text: string;
  files: File[] | null;
}

type FormHelpers = FormikHelpers<DisableSupplierItemsFormValues>;

const acceptedFiles = {
  'text/plain': ['.txt'],
};

export const DisableSupplierItemsForm = memo((props: DisableSupplierItemsFormProps) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const suppliersOptions = useAppSelector(getSuppliersOptions);

  const validationSchema = yup.object().shape({
    supplierId: yup.string().required('Выберите поставщика'),
    articlesType: yup.string().required('Выберите тип артикула'),
    inputType: yup.string().required('Выберите способ'),
    comment: yup.string().required('Введите комметарий'),
    text: yup.string().when('inputType', {
      is: InputType.Text,
      then: yup.string().trim().required('Укажите артикулы'),
    }),
    files: yup.mixed().when('inputType', {
      is: InputType.File,
      then: yup.mixed().required('Выберите файл'),
    }),
  });

  const initialValues = useMemo<DisableSupplierItemsFormValues>(
    () => ({
      supplierId: '',
      articlesType: '',
      inputType: '',
      comment: '',
      recovery: false,
      text: '',
      files: null,
    }),
    [],
  );

  const onSubmit = (values: DisableSupplierItemsFormValues, { resetForm }: FormHelpers) => {
    const { articlesType, supplierId, comment, inputType, recovery, text, files } = values;

    if (inputType === InputType.File && files?.[0]) {
      dispatch(
        setSupplierItemsDisabledFromInput({
          articlesType: articlesType as ArticlesType,
          supplierId,
          comment,
          recovery,
          inputType: InputType.File,
          input: files?.[0],
        }),
      );
    }

    if (inputType === InputType.Text) {
      dispatch(
        setSupplierItemsDisabledFromInput({
          articlesType: articlesType as ArticlesType,
          supplierId,
          comment,
          recovery,
          inputType: InputType.Text,
          input: text,
        }),
      );
    }

    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      className={className}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} alignItems={'flex-end'}>
            <Grid container item xs={10} spacing={2} style={{ marginTop: 2 }}>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Включить товары"
                  control={
                    <Switch
                      id="recovery"
                      name="recovery"
                      checked={formik.values.recovery}
                      onChange={formik.handleChange}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="supplierId"
                  name="supplierId"
                  label="Поставщик"
                  options={suppliersOptions}
                  value={formik.values.supplierId}
                  setFieldValue={formik.setFieldValue}
                  error={formik.touched.supplierId && Boolean(formik.errors.supplierId)}
                  helperText={formik.touched.supplierId && formik.errors.supplierId}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="articlesType"
                  name="articlesType"
                  label="Тип артикулов"
                  options={articlesTypeOptions}
                  value={formik.values.articlesType}
                  setFieldValue={formik.setFieldValue}
                  error={formik.touched.articlesType && Boolean(formik.errors.articlesType)}
                  helperText={formik.touched.articlesType && formik.errors.articlesType}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="inputType"
                  name="inputType"
                  label="Откуда взять артикулы?"
                  options={inputTypeOptions}
                  value={formik.values.inputType}
                  setFieldValue={formik.setFieldValue}
                  error={formik.touched.inputType && Boolean(formik.errors.inputType)}
                  helperText={formik.touched.inputType && formik.errors.inputType}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="comment"
                  name="comment"
                  label="Комментарий"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment}
                />
              </Grid>
              {formik.values.inputType === InputType.Text && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="text"
                    name="text"
                    label="Укажите артикулы через пробел или перенос строки"
                    multiline
                    minRows={8}
                    value={formik.values.text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.text && Boolean(formik.errors.text)}
                    helperText={formik.touched.text && formik.errors.text}
                  />
                </Grid>
              )}
              {formik.values.inputType === InputType.File && (
                <Grid item xs={12}>
                  <Stack spacing={1.5} alignItems="center">
                    <UploadSingleFile
                      subtitle={
                        'Принимаются файлы в форматах: .txt. Укажите артикулы через пробел или перенос строки.'
                      }
                      setFieldValue={formik.setFieldValue}
                      file={formik.values.files}
                      error={formik.touched.files && !!formik.errors.files}
                      accept={acceptedFiles}
                    />
                    {formik.touched.files && formik.errors.files && (
                      <FormHelperText error id="standard-weight-helper-text-password-login">
                        {formik.errors.files}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              )}
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                // loading={updateLoading}
                type="submit"
                variant="contained"
                loadingPosition="center"
                fullWidth
              >
                {'Загрузить'}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
});
