import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import { SuppliersCommentsResponse } from '../../../../SuppliersComments/model/types/suppliersCommentsTypes';

export const fetchSupplierComments = createAsyncThunk<
  SuppliersCommentsResponse,
  string,
  ThunkConfig<string>
>('supplier/fetchSupplierComments', async (id, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const response = await $api.get<SuppliersCommentsResponse>(`/suppliers/comments`, {
      params: {
        sort: `["created_at", "DESC"]`,
        filter: `[{"column": "supplier_id", "op": "equal", "values": ["${id}"]}]`,
      },
    });

    return response.data;
  } catch (err) {
    const errorText = `Ошибка при загрузке комментариев поставщика: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
