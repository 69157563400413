import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import {
  SetSuppliersItemsDisabledInput,
  SuppliersItemsDisabledApiInput,
} from '../../types/suppliersItemsDisabledTypes';

export const setSuppliersItemsDisabled = createAsyncThunk<
  void,
  SetSuppliersItemsDisabledInput,
  ThunkConfig<string>
>('supplierItems/setSuppliersItemsDisabled', async (input, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const suppliersMap = input.suppliersItems.reduce((acc, row) => {
      const supplierArticles = acc.get(row.supplierId) ?? [];
      acc.set(row.supplierId, [...supplierArticles, row.supplierArticle]);

      return acc;
    }, new Map());

    await Promise.all(
      Array.from(suppliersMap.entries()).map(async ([supplier, articleIds]) => {
        const apiInput: SuppliersItemsDisabledApiInput = {
          supplier_id: supplier,
          supplier_articles: articleIds,
          articles: [],
          recovery: input.recovery,
          comment: input.comment,
        };

        await $api.post<SuppliersItemsDisabledApiInput>('/suppliers/items/disabled', apiInput);

        input.onSuccess?.();

        dispatch(
          openSnackbar({
            open: true,
            message: input.recovery ? 'Товары возвращены в продажу' : 'Товары сняты c продажи',
            variant: 'alert',
            alert: {
              color: 'success',
            },
          }),
        );
      }),
    );
  } catch (err) {
    const errorText = `Ошибка при снятии с продажи товаров: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
