import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from 'app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from 'app/deprecated/store/store';
import $api from 'shared/api/api';
import { Link, LinkResponse, CreateLinkInput } from '../../types/link';
import { linksActions } from '../../slices/linksSlice';
import { camelCaseObject } from 'shared/lib/utils/formatting/formatResponse';

export const createLink = createAsyncThunk<LinkResponse, CreateLinkInput, ThunkConfig<string>>(
  'link/createLink',
  async (input, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.post<LinkResponse>('/links', input);

      dispatch(linksActions.addLink(camelCaseObject(response.data) as Link));

      dispatch(
        openSnackbar({
          open: true,
          message: 'Ссылка создана',
          variant: 'alert',
          alert: {
            color: 'success',
          },
        }),
      );

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при создании ссылки: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
