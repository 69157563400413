import React, { memo, useEffect, useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Grid, TextField } from '@mui/material';
import { isAuthByHeader, isAuthByPassword } from '../../../../entities/Link/model/lib/linkUtils';
import { AuthTypeEnum, authTypeOptions } from '../../../../entities/Link/model/lib/linksConstants';
import { RolesEnum } from '../../../../shared/lib/utils/constants/commonConstants';
import { Autocomplete } from '../../../../shared/ui/deprecated/Autocomplete/Autocomplete';
import LoadingButton from '../../../../shared/ui/deprecated/@extended/LoadingButton';
import { Select } from '../../../../shared/ui/deprecated/Select/Select';
import { getSuppliersOptions } from '../../../../shared/lib/utils/selectors/commonDataSelectors';
import { UploadOutlined } from '@ant-design/icons';
import {
  getCreateJobFromLinkDataLoading,
  getFormInitialValues,
} from '../model/selectors/createJobFromLinkDataSelectors';
import {
  CreateJobFromLinkDataFormValues,
  CreateJobFromLinkDataInput,
} from '../model/types/createJobFromLinkData';
import { createJobFromLinkData } from '../model/services/createJobFromLinkData/createJobFromLinkData';
import { GroupedSelect } from '../../../../shared/ui/deprecated/GroupedSelect/GroupedSelect';
import { contentTypeGroupedOptions } from '../../../../entities/Job';
import { createJobFromLinkDataActions } from '../model/slices/createJobFromLinkDataSlice';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import ThemeCustomization from '../../../../app/deprecated/themes';

type FormHelpers = FormikHelpers<CreateJobFromLinkDataFormValues>;

export const CreateJobFromLinkDataForm = memo(() => {
  const suppliersOptions = useAppSelector(getSuppliersOptions);
  const createJobFromLinkDataLoading = useAppSelector(getCreateJobFromLinkDataLoading);
  const formInitialValues = useAppSelector(getFormInitialValues);
  const { user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(createJobFromLinkDataActions.clearFormInitialData());
    };
  }, [dispatch]);

  const initialValues = useMemo(() => {
    if (formInitialValues) {
      return formInitialValues;
    }

    return {
      supplierId: '',
      contentType: '',
      url: '',
      authType: '',
      username: '',
      password: '',
      headerKey: '',
      headerValue: '',
    };
  }, [formInitialValues]);

  const validationSchema = yup.object().shape({
    contentType: yup.string().required('Выберите тип данных'),
    url: yup.string().trim().url('Ссылка не корректна').required('Укажите ссылку'),
    authType: yup.string().required('Выберите тип авторизации'),
    username: yup.string().when('authType', {
      is: isAuthByPassword,
      then: yup.string().trim().required('Укажите логин'),
    }),
    password: yup.string().when('authType', {
      is: isAuthByPassword,
      then: yup.string().trim().required('Укажите пароль'),
    }),
    headerKey: yup.string().when('authType', {
      is: isAuthByHeader,
      then: yup.string().trim().required('Укажите header_key'),
    }),
    headerValue: yup.string().when('authType', {
      is: isAuthByHeader,
      then: yup.string().trim().required('Укажите header_value'),
    }),
  });

  const onSubmit = (values: CreateJobFromLinkDataFormValues, { resetForm }: FormHelpers) => {
    const input: CreateJobFromLinkDataInput = {
      url: values.url.trim(),
      user_id: user?.id,
      supplier_id: values.supplierId,
      content_types: [values.contentType],
      format: 'xml',
      auth_type: values.authType,
    };

    if (values.authType === AuthTypeEnum.Basic || values.authType === AuthTypeEnum.Ftp) {
      input.username = values.username?.trim();
      input.password = values.password?.trim();
    }

    if (values.authType === AuthTypeEnum.Header) {
      input.header_key = values.headerKey?.trim();
      input.header_value = values.headerValue?.trim();
    }

    dispatch(createJobFromLinkData(input));
    resetForm();
  };

  return (
    <ThemeCustomization>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} alignItems={'flex-end'}>
              <Grid container item xs={10} spacing={2} style={{ marginTop: 2 }}>
                {user?.role === RolesEnum.Admin && (
                  <Grid item xs={4}>
                    <Autocomplete
                      id="supplierId"
                      name="supplierId"
                      label="Поставщик"
                      options={suppliersOptions}
                      value={formik.values.supplierId}
                      setFieldValue={formik.setFieldValue}
                      error={formik.touched.supplierId && Boolean(formik.errors.supplierId)}
                      helperText={formik.touched.supplierId && formik.errors.supplierId}
                    />
                  </Grid>
                )}
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="url"
                    name="url"
                    label="Ссылка"
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.url && Boolean(formik.errors.url)}
                    helperText={formik.touched.url && formik.errors.url}
                  />
                </Grid>
                <Grid item xs={8}>
                  <GroupedSelect
                    id="contentType"
                    name="contentType"
                    label="Что передаем"
                    groupedOptions={contentTypeGroupedOptions}
                    value={formik.values.contentType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant={'outlined'}
                    error={formik.touched.contentType && Boolean(formik.errors.contentType)}
                    helperText={formik.touched.contentType && formik.errors.contentType}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    id="authType"
                    name="authType"
                    label="Тип авторизации"
                    options={authTypeOptions}
                    value={formik.values.authType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant={'outlined'}
                    error={formik.touched.authType && Boolean(formik.errors.authType)}
                    helperText={formik.touched.authType && formik.errors.authType}
                  />
                </Grid>
                {(formik.values.authType === AuthTypeEnum.Basic ||
                  formik.values.authType === AuthTypeEnum.Ftp) && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="username"
                        name="username"
                        label="Логин"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Пароль"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                      />
                    </Grid>
                  </>
                )}
                {formik.values.authType === AuthTypeEnum.Header && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="headerKey"
                        name="headerKey"
                        label="header_key"
                        value={formik.values.headerKey}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.headerKey && Boolean(formik.errors.headerKey)}
                        helperText={formik.touched.headerKey && formik.errors.headerKey}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="headerKey"
                        name="headerValue"
                        label="header_value"
                        value={formik.values.headerValue}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.headerValue && Boolean(formik.errors.headerValue)}
                        helperText={formik.touched.headerValue && formik.errors.headerValue}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={2}>
                <LoadingButton
                  loading={createJobFromLinkDataLoading}
                  type="submit"
                  variant="contained"
                  loadingPosition="start"
                  startIcon={<UploadOutlined />}
                  fullWidth
                >
                  {'Загрузить'}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </ThemeCustomization>
  );
});
