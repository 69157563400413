import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { camelCaseObject } from 'shared/lib/utils/formatting/formatResponse';
import { Email } from '../types/email';
import { fetchEmail } from '../services/fetchEmail/fetchEmail';
import { createEmail } from '../services/createEmail/createEmail';
import { updateEmail } from '../services/updateEmail/updateEmail';

interface EmailFormState {
  data?: Email;
  loading: boolean;
  updateLoading: boolean;
  createLoading: boolean;
}

const initialState: EmailFormState = {
  data: undefined,
  loading: false,
  updateLoading: false,
  createLoading: false,
};

export const emailFormSlice = createSlice({
  name: 'emailFormSlice',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<Email>) {
      state.data = action.payload;
    },
    resetData(state) {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    // fetchEmail
    builder.addCase(fetchEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEmail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = camelCaseObject(payload) as Email;
    });
    builder.addCase(fetchEmail.rejected, (state) => {
      state.loading = false;
    });
    // createLink
    builder.addCase(createEmail.pending, (state) => {
      state.createLoading = true;
    });
    builder.addCase(createEmail.fulfilled, (state, { payload }) => {
      state.createLoading = false;
      state.data = camelCaseObject(payload) as Email;
    });
    builder.addCase(createEmail.rejected, (state) => {
      state.createLoading = false;
    });
    // updateEmail
    builder.addCase(updateEmail.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateEmail.fulfilled, (state) => {
      state.updateLoading = false;
    });
    builder.addCase(updateEmail.rejected, (state) => {
      state.updateLoading = false;
    });
  },
});

export const { actions: emailFormActions } = emailFormSlice;
export const { reducer: emailFormReducer } = emailFormSlice;
