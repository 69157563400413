import React, { lazy } from 'react';
import CommonLayout from 'shared/ui/deprecated/Layout/CommonLayout';
import Loadable from 'shared/ui/deprecated/Loadable';
import AuthGuard from 'shared/lib/utils/route-guard/AuthGuard';
import ProtectedRoute from './ProtectedRoute';
import { RolesEnum } from '../../../shared/lib/utils/constants/commonConstants';
import { SupplierItemForm } from '../../../pages/deprecated/SupplierItemForm';
import {
  Supplier,
  SupplierCreateForm,
  SupplierEditForm,
} from '../../../pages/deprecated/Suppliers';
import { SupplierOrdersPage } from '../../../pages/redesigned/SupplierOrdersPage';
import { UploadCatalog } from '../../../pages/deprecated/UploadCatalog';
import { LinkEditForm } from '../../../widgets/deprecated/LinkEditForm';
import { LinkViewForm } from '../../../widgets/deprecated/LinkViewForm';
import { Jobs } from '../../../pages/deprecated/Jobs';
import { SupplierOrderPage } from '../../../pages/deprecated/SupplierOrderPage';
import { EmailViewForm } from '../../../widgets/deprecated/EmailViewForm';
import { EmailEditForm } from '../../../widgets/deprecated/EmailEditForm';
import { JobDetailsPage } from '../../../pages/deprecated/JobDetailsPage';
import { DisableSuppliersItemsPage } from '../../../pages/deprecated/DisableSuppliersItemsPage';
import { UserViewForm } from '../../../widgets/deprecated/UserViewForm';
import { UserEditForm } from '../../../widgets/deprecated/UserEditForm';
import { UserCreateForm } from '../../../widgets/deprecated/UserCreareForm';
import {
  getRoute400,
  getRoute500,
  getRouteDisableSuppliersItems,
  getRouteJobDetails,
  getRouteJobs,
  getRouteMain,
  getRouteMaintenance,
  getRouteSupplierCreate,
  getRouteSupplierEdit,
  getRouteSuppliers,
  getRouteSuppliersComments,
  getRouteSuppliersItemDetails,
  getRouteSuppliersItems,
  getRouteSuppliersOrderDetails,
  getRouteSuppliersOrders,
  getRouteSupplierTab,
  getRouteUnauthorized,
  getRouteUnderConstruction,
  getRouteUploadCatalogEmail,
  getRouteUploadCatalogEmailEdit,
  getRouteUploadCatalogLink,
  getRouteUploadCatalogLinkEdit,
  getRouteUploadCatalogTab,
  getRouteUserCreate,
  getRouteUserDetails,
  getRouteUserEdit,
  getRouteUsers,
} from '../../../shared/const/router';
import { MainLayout } from '../../../shared/layouts/MainLayout';
import { NavigationDrawer } from '../../../widgets/redesigned/NavigationDrawer';
import { Outlet } from 'react-router-dom';
import { SupplierItemsPage } from '../../../pages/redesigned/SupplierItemsPage';
import { SuppliersPage } from '../../../pages/redesigned/SuppliersPage';
import { SuppliersCommentsPage } from '../../../pages/redesigned/SuppliersCommentPage';
import { UsersPage } from '../../../pages/redesigned/UsersPage';

// pages routing
const Unauthorized = Loadable(lazy(() => import('pages/deprecated/Unauthorized/Unauthorized')));
const MaintenanceError = Loadable(lazy(() => import('pages/deprecated/Maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/deprecated/Maintenance/500')));
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import('pages/deprecated/Maintenance/UnderConstruction')),
);

const MainRoutes = {
  path: '/',
  children: [
    {
      path: getRouteMain(),
      element: (
        <AuthGuard>
          <MainLayout drawer={<NavigationDrawer />} content={<Outlet />} />
        </AuthGuard>
      ),
      children: [
        {
          path: getRouteUploadCatalogTab(':tab'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <UploadCatalog />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteUploadCatalogLink(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <LinkViewForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteUploadCatalogLinkEdit(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <LinkEditForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteUploadCatalogEmail(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <EmailViewForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteUploadCatalogEmailEdit(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <EmailEditForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteJobs(),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <Jobs />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteJobDetails(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <JobDetailsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteSuppliers(),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <SuppliersPage />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteSupplierTab(':id', ':tab'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <Supplier />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteSupplierCreate(),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <SupplierCreateForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteSupplierEdit(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <SupplierEditForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteSuppliersItems(),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <SupplierItemsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteSuppliersItemDetails(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <SupplierItemForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteSuppliersComments(),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <SuppliersCommentsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteUsers(),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <UsersPage />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteUserCreate(),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <UserCreateForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteUserDetails(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <UserViewForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteUserEdit(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin]}>
              <UserEditForm />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteSuppliersOrders(),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <SupplierOrdersPage />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteSuppliersOrderDetails(':id'),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <SupplierOrderPage />
            </ProtectedRoute>
          ),
        },
        {
          path: getRouteDisableSuppliersItems(),
          element: (
            <ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker]}>
              <DisableSuppliersItemsPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: getRouteUnauthorized(),
      element: <Unauthorized />,
    },
    {
      path: getRouteMaintenance(),
      element: <CommonLayout />,
      children: [
        {
          path: getRoute400(),
          element: <MaintenanceError />,
        },
        {
          path: getRoute500(),
          element: <MaintenanceError500 />,
        },
        {
          path: getRouteUnderConstruction(),
          element: <MaintenanceUnderConstruction />,
        },
      ],
    },
  ],
};

export default MainRoutes;
