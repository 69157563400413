import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from 'app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from 'app/deprecated/store/store';
import $api from 'shared/api/api';
import { CreateEmailInput, Email, EmailResponse } from '../../types/email';
import { camelCaseObject } from '../../../../../shared/lib/utils/formatting/formatResponse';
import { emailsActions } from '../../slices/emailsSlice';

export const createEmail = createAsyncThunk<EmailResponse, CreateEmailInput, ThunkConfig<string>>(
  'email/createEmail',
  async (input, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.post<EmailResponse>('/emails', input);

      dispatch(emailsActions.addEmail(camelCaseObject(response.data) as Email));

      dispatch(
        openSnackbar({
          open: true,
          message: 'Email создан',
          variant: 'alert',
          alert: {
            color: 'success',
          },
        }),
      );

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при создании email: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
