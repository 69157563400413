import React, { memo } from 'react';
import cls from './SupplierOrderItems.module.scss';
import MainCard from '../../../../shared/ui/deprecated/MainCard';
import { formatPrice } from '../../../../shared/lib/utils/formatting/formatPrice/formatPrice';
import { getSupplierOrderItemsList } from '../../../SupplierOrderItem';
import { Stack } from '@mui/material';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';

interface SupplierOrderItemsProps {
  className?: string;
}

export const SupplierOrderItems = memo((props: SupplierOrderItemsProps) => {
  const { className } = props;

  const orderItems = useAppSelector(getSupplierOrderItemsList);

  return (
    <Stack gap={2} className={className}>
      {orderItems?.map((item) => (
        <MainCard key={item.id} className={cls.card}>
          <div className={cls.orderItem}>
            <div className={cls.orderItemData}>
              <div>{item.name}</div>
              <div className={cls.orderItemDataItem}>
                <span className={cls.itemTitle}>Артикул: </span>
                <span>{item.article}</span>
              </div>
              <div className={cls.orderItemDataItem}>
                <span className={cls.itemTitle}>Код поставщика: </span>
                <span>{item.supplierArticle}</span>
                <span>{item.supplierArticle}</span>
              </div>
              <div className={cls.orderItemDataItem}>
                <span className={cls.itemTitle}>Номенклатура: </span>
                <span>{item.supplierName}</span>
              </div>
            </div>
            <div className={cls.orderItemPriceBlock}>
              <div className={cls.orderItemPrice}>
                <div>
                  <div className={cls.itemTitle}>Кол-во</div>
                  <div>{item.quantity}</div>
                </div>
                <div>
                  <div className={cls.itemTitle}>Цена</div>
                  <div>{formatPrice(item.price)}</div>
                </div>
                <div>
                  <div className={cls.itemTitle}>Скидка</div>
                  <div>{formatPrice(item.discount)}</div>
                </div>
                <div>
                  <div className={cls.itemTitle}>Сумма</div>
                  <div>{formatPrice(item.sum)}</div>
                </div>
              </div>
            </div>
          </div>
        </MainCard>
      ))}
    </Stack>
  );
});
