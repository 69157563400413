// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SupplierOrderForm_right__C-A-s {\n  width: 338px;\n}", "",{"version":3,"sources":["webpack://src/entities/SupplierOrder/ui/SupplierOrderForm/SupplierOrderForm.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF","sourcesContent":[".right {\n  width: 338px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"right": "SupplierOrderForm_right__C-A-s"
};
export default ___CSS_LOADER_EXPORT___;
