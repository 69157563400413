import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../app/deprecated/store/store';
import $api from '../../../../../shared/api/api';
import { LinkResponse } from '../../types/link';

export const runLink = createAsyncThunk<LinkResponse, string, ThunkConfig<string>>(
  'link/runLink',
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.get<LinkResponse>(`/link/${id}/run`);

      dispatch(
        openSnackbar({
          open: true,
          message: 'Загрузка по ссылке создана',
          variant: 'alert',
          alert: {
            color: 'success',
          },
        }),
      );

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при создании загрузки по ссылке: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
