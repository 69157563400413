import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { FormikHelpers } from 'formik';

import { Select } from '../Select/Select';
import {
  SchedulePeriodEnum,
  dayOptions,
  schedulePeriodOptions,
  dayOfWeekOptions,
  hourOptions,
  minuteOptions,
  hoursIntervalOptions,
  minutesIntervalOptions,
} from './utils/constants';
import { convertCronToSchedule, getCroneValue } from './utils/helpers';

interface SelectCronScheduleProps {
  id: string;
  name?: string;
  value: string;
  onChange?: (cronValue: string | null) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  setFieldValue?: FormikHelpers<any>['setFieldValue'];
}

enum ScheduleFieldsEnum {
  period = 'period',
  day = 'day',
  dayOfWeek = 'dayOfWeek',
  hour = 'hour',
  minute = 'minute',
  hoursInterval = 'hoursInterval',
  minutesInterval = 'minutesInterval',
}

export interface Schedule {
  period: SchedulePeriodEnum | '';
  day: number;
  dayOfWeek: number;
  hour: number;
  minute: number;
  hoursInterval: number;
  minutesInterval: number;
}

const initialState: Schedule = {
  period: '',
  day: 1,
  dayOfWeek: 1,
  hour: 0,
  minute: 0,
  hoursInterval: 1,
  minutesInterval: 10,
};

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
export const SelectCronSchedule: React.FC<SelectCronScheduleProps> = React.memo((props) => {
  const { id, name, value, onChange, error, helperText, disabled, setFieldValue } = props;

  const [schedule, setSchedule] = useState<Schedule>(
    value ? convertCronToSchedule(value) : initialState,
  );

  const handleScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSchedule({
      ...schedule,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    // reset
    if (!value) {
      setSchedule(initialState);
    }
  }, [value]);

  useEffect(() => {
    const croneValue = getCroneValue(schedule);

    // set formik field value
    if (name && setFieldValue) {
      setFieldValue(name, croneValue);
    }

    onChange?.(croneValue);
  }, [schedule, name, onChange, setFieldValue]);

  return (
    <>
      <Grid item xs={4}>
        <Select
          id={`${id}-period`}
          name={ScheduleFieldsEnum.period}
          label="Выберите расписание"
          options={schedulePeriodOptions}
          value={schedule.period}
          onChange={handleScheduleChange}
          variant={'outlined'}
          error={error}
          helperText={helperText}
          disabled={disabled}
        />
      </Grid>
      {schedule.period && (
        <Grid item xs={4}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {schedule.period === SchedulePeriodEnum.Monthly && (
              <Select
                id={`${id}-day`}
                name={ScheduleFieldsEnum.day}
                label="День месяца"
                options={dayOptions}
                value={schedule.day}
                onChange={handleScheduleChange}
                variant={'outlined'}
                disabled={disabled}
              />
            )}
            {schedule.period === SchedulePeriodEnum.Weekly && (
              <Select
                id={`${id}-dayOfWeek`}
                name={ScheduleFieldsEnum.dayOfWeek}
                label="День недели"
                options={dayOfWeekOptions}
                value={schedule.dayOfWeek}
                onChange={handleScheduleChange}
                variant={'outlined'}
                disabled={disabled}
              />
            )}
            {(schedule.period === SchedulePeriodEnum.Monthly ||
              schedule.period === SchedulePeriodEnum.Weekly ||
              schedule.period === SchedulePeriodEnum.Daily) && (
              <>
                <span>{'в'}</span>
                <Select
                  id={`${id}-hour`}
                  name={ScheduleFieldsEnum.hour}
                  label="Часы"
                  options={hourOptions}
                  value={schedule.hour}
                  onChange={handleScheduleChange}
                  variant={'outlined'}
                  disabled={disabled}
                />
                <span>{':'}</span>
                <Select
                  id={`${id}-minute`}
                  name={ScheduleFieldsEnum.minute}
                  label="Минуты"
                  options={minuteOptions}
                  value={schedule.minute}
                  onChange={handleScheduleChange}
                  variant={'outlined'}
                  disabled={disabled}
                />
              </>
            )}
            {schedule.period === SchedulePeriodEnum.SeveralTimesADay && (
              <Select
                id={`${id}-hoursInterval`}
                name={ScheduleFieldsEnum.hoursInterval}
                label="Интервал"
                options={hoursIntervalOptions}
                value={schedule.hoursInterval}
                onChange={handleScheduleChange}
                variant={'outlined'}
                disabled={disabled}
              />
            )}
            {schedule.period === SchedulePeriodEnum.SeveralTimesAHour && (
              <Select
                id={`${id}-minutesInterval`}
                name={ScheduleFieldsEnum.minutesInterval}
                label="Интервал"
                options={minutesIntervalOptions}
                value={schedule.minutesInterval}
                onChange={handleScheduleChange}
                variant={'outlined'}
                disabled={disabled}
              />
            )}
          </Stack>
        </Grid>
      )}
    </>
  );
});
