import { range } from 'lodash';

export enum SchedulePeriodEnum {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Daily = 'daily',
  SeveralTimesADay = 'severalTimesADay',
  SeveralTimesAHour = 'severalTimesAHour',
}

export const schedulePeriodOptions = [
  {
    value: SchedulePeriodEnum.Monthly,
    label: 'Ежемесячно',
  },
  {
    value: SchedulePeriodEnum.Weekly,
    label: 'Еженедельно',
  },
  {
    value: SchedulePeriodEnum.Daily,
    label: 'Ежедневно',
  },
  {
    value: SchedulePeriodEnum.SeveralTimesADay,
    label: 'Несколько раз в день',
  },
  {
    value: SchedulePeriodEnum.SeveralTimesAHour,
    label: 'Несколько раз в час',
  },
];

export const DAYS = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

export const dayOptions = range(1, 32).map((day) => ({
  value: day,
  label: day.toString(),
}));

export const dayOfWeekOptions = range(1, 7).map((day) => ({
  value: day,
  label: DAYS[day - 1],
}));

export const hourOptions = range(0, 24).map((hour) => ({
  value: hour,
  label: hour.toString().padStart(2, '0'),
}));

export const minuteOptions = range(0, 60).map((minute) => ({
  value: minute,
  label: minute.toString().padStart(2, '0'),
}));

export const hoursIntervalOptions = [
  {
    value: 1,
    label: 'Каждый час',
  },
  {
    value: 2,
    label: 'Каждые 2 часа',
  },
  {
    value: 4,
    label: 'Каждые 4 часа',
  },
  {
    value: 8,
    label: 'Каждые 8 часа',
  },
  {
    value: 12,
    label: 'Каждые 12 часа',
  },
];

export const minutesIntervalOptions = [
  {
    value: 10,
    label: 'Каждые 10 минут',
  },
  {
    value: 30,
    label: 'Каждые 30 минут',
  },
];
