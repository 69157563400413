import { createSlice } from '@reduxjs/toolkit';
import { stopJob } from '../services/stopJob/stopJob';

interface StopJobState {
  loading: boolean;
}

const initialState: StopJobState = {
  loading: false,
};

export const stopJobSlice = createSlice({
  name: 'stopJobSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(stopJob.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(stopJob.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(stopJob.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { reducer: stopJobReducer } = stopJobSlice;
