import React, { createContext, useEffect } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import {
  login as loginAction,
  logout as logoutAction,
} from 'app/deprecated/store/reducers/authSlice';

// project import
import Loader from 'shared/ui/deprecated/Loader';
import { KeyedObject } from 'shared/types/root';
import { JWTContextType } from 'shared/types/auth';
import { AuthService } from '../../../shared/services/AuthService';
import { UserService } from '../../../shared/services/UserService';
import { camelCaseObject } from '../../../shared/lib/utils/formatting/formatResponse';
import { User } from '../../../entities/User';
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector';

const getUserId: (st: string) => string = (token) => {
  if (!token) {
    return null;
  }
  const decoded: KeyedObject = jwtDecode(token);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.jti;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const state = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const init = async () => {
      try {
        const token = window.localStorage.getItem('token');
        if (token) {
          const userId = getUserId(token);
          const response = await UserService.getUser(userId);
          const user = response.data;
          dispatch(loginAction(camelCaseObject(user) as User));
        } else {
          dispatch(logoutAction());
        }
      } catch (err) {
        console.error(err);
        dispatch(logoutAction());
      }
    };

    init();
  }, [dispatch]);

  const login = async (email: string, password: string) => {
    const response = await AuthService.auth(email, password);
    const { token, refresh_token } = response.data;
    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refresh_token);

    const userId = getUserId(token);
    const userResponse = await UserService.getUser(userId);
    const user = userResponse.data;
    dispatch(loginAction(camelCaseObject(user) as User));
  };

  const register = async () => {};

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    dispatch(logoutAction());
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{ ...state, login, logout, register, resetPassword, updateProfile }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
