import axios from 'axios';
import { AuthResponse } from '../types/auth/AuthApiTypes';
import { logout } from '../../app/deprecated/store/reducers/authSlice';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { REFRESH_TOKEN_LOCAL_STORAGE_KEY, TOKEN_LOCAL_STORAGE_KEY } from '../const/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

export const initApiInterceptors = (store: ToolkitStore) => {
  $api.interceptors.response.use(
    (config) => config,
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && originalRequest && !originalRequest._isRetry) {
        originalRequest._isRetry = true;
        try {
          const response = await axios.post<AuthResponse>(`${API_URL}/refresh`, {
            token: localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY),
            refresh_token: localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY),
          });
          localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, response.data.token);
          localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, response.data.refresh_token);
          return $api.request(originalRequest);
        } catch (err) {
          console.log('Пользователь не авторизован');
          await store.dispatch(logout());
        }
      }

      throw error;
    },
  );
};

export default $api;
