// material-ui
import { Typography, Stack, CardMedia } from '@mui/material';

// assets
import UploadCover from 'shared/assets/images/upload/upload.svg';

type PlaceholderContentProps = {
  subtitle?: string;
};

// ==============================|| UPLOAD - PLACEHOLDER ||============================== //

export default function PlaceholderContent({ subtitle }: PlaceholderContentProps) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <CardMedia component="img" image={UploadCover} sx={{ width: 150 }} />
      <Stack sx={{ p: 3 }} spacing={1}>
        <Typography variant="h5">Выберите файл или перетащите его сюда</Typography>
        {subtitle && <Typography color="secondary">{subtitle}</Typography>}
      </Stack>
    </Stack>
  );
}
