import React, { useEffect } from 'react';
import Routes from 'app/deprecated/routes';
import ScrollTop from 'shared/ui/deprecated/ScrollTop';
import Snackbar from 'shared/ui/deprecated/@extended/Snackbar';
import { JWTProvider as AuthProvider } from 'app/deprecated/contexts/JWTContext';
import { fetchCommonData } from './deprecated/store/actionCreators/commonDataActionCreators';
import { useAppDispatch } from '../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../shared/lib/hooks/useAppSelector/useAppSelector';

const App = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      return;
    }
    dispatch(fetchCommonData());
  }, [user, dispatch]);

  return (
    <ScrollTop>
      <AuthProvider>
        <>
          <Routes />
          <Snackbar />
        </>
      </AuthProvider>
    </ScrollTop>
  );
};

export default App;
