import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../app/deprecated/store/store';
import $api from '../../../../../shared/api/api';
import { SupplierOrdersResponse } from '../../../../SupplierOrder/model/types/supplierOrders';
import { SupplierOrderItemsResponse } from '../../types/supplierOrderItems';

interface Response {
  order: SupplierOrdersResponse;
  orderItems: SupplierOrderItemsResponse;
}

export const fetchSupplierOrdersItemsByOrder = createAsyncThunk<
  Response,
  number,
  ThunkConfig<string>
>('supplierOrders/fetchSupplierOrdersItemsByOrder', async (rcrmOrderId, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const params = {
      filter: JSON.stringify([
        {
          column: 'rcrm_order_id',
          op: 'equal',
          values: [rcrmOrderId],
        },
      ]),
    };

    const [ordersResponse, orderItemsResponse] = await Promise.all([
      $api.get<SupplierOrdersResponse>('/suppliers/orders', { params: params }),
      $api.get<SupplierOrderItemsResponse>('/suppliers/order_items/', { params: params }),
    ]);

    return {
      order: ordersResponse.data,
      orderItems: orderItemsResponse.data,
    };
  } catch (err) {
    const errorText = `Ошибка при загрузке заказов: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
