// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StickyContentLayout_StickyContentLayout__3fIWP {\n  display: grid;\n  grid-template-areas: \"content right\";\n  grid-template-columns: 1fr min-content;\n  grid-gap: 16px;\n  gap: 16px;\n}\n\n.StickyContentLayout_content__27eez {\n  grid-area: content;\n  width: 100%;\n  max-width: 1200px;\n  justify-self: center;\n}\n\n.StickyContentLayout_right__2R3XN {\n  grid-area: right;\n}\n\n.StickyContentLayout_right__2R3XN {\n  position: sticky;\n  top: 32px;\n  height: -moz-fit-content;\n  height: fit-content;\n}", "",{"version":3,"sources":["webpack://src/shared/layouts/StickyContentLayout/StickyContentLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oCAAA;EACA,sCAAA;EACA,cAAA;EAAA,SAAA;AACF;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,oBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,SAAA;EACA,wBAAA;EAAA,mBAAA;AAAF","sourcesContent":[".StickyContentLayout {\n  display: grid;\n  grid-template-areas: \"content right\";\n  grid-template-columns: 1fr min-content;\n  gap: 16px;\n}\n\n\n.content {\n  grid-area: content;\n  width: 100%;\n  max-width: 1200px;\n  justify-self: center;\n}\n\n.right {\n  grid-area: right;\n}\n\n.right {\n  position: sticky;\n  top: 32px;\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StickyContentLayout": "StickyContentLayout_StickyContentLayout__3fIWP",
	"content": "StickyContentLayout_content__27eez",
	"right": "StickyContentLayout_right__2R3XN"
};
export default ___CSS_LOADER_EXPORT___;
