import { format, isValid, parseISO } from 'date-fns';
import ru from 'date-fns/locale/ru';

const notSetMessage = 'Не задана';

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_WITH_TIME_FORMAT = 'dd.MM.yyyy HH:mm';

export const getDateString = (
  date: number | string | Date | null = null,
  formatStr = DATE_FORMAT,
  locale = ru,
) => {
  if (!date) {
    return notSetMessage;
  }

  const customDate = isValid(new Date(+date)) ? new Date(+date).toString() : date.toString();
  const parsedISO = parseISO(new Date(customDate).toISOString());

  return format(parsedISO, formatStr, { locale });
};
