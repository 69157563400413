import React from 'react';

export const PartIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.41131 0.752041L9.41062 0.75H9.23143C4.68212 0.75 0.981445 4.45062 0.981445 8.99995C0.981445 11.0816 1.76032 13.0703 3.1742 14.599C4.73228 16.2838 6.93951 17.25 9.23146 17.25C13.7808 17.25 17.4814 13.5494 17.4814 9.00005C17.4814 4.51083 13.8779 0.848033 9.41131 0.752041ZM16.3412 9.00014C16.3412 12.9029 13.1798 16.0816 9.28359 16.1099H9.23143C7.44452 16.1099 5.70713 15.423 4.39816 14.212L9.63457 8.97599L9.63458 8.97598C9.74216 8.8684 9.80123 8.72441 9.80123 8.57335V1.91303C13.4479 2.20624 16.3412 5.29257 16.3412 9.00014ZM8.66146 8.33721L3.62596 13.3723C2.65246 12.1262 2.12148 10.5919 2.12148 8.99995C2.12148 5.29231 5.01489 2.20601 8.66146 1.91283V8.33721Z"
      fill="#8C8C8C"
      stroke="#8C8C8C"
      strokeWidth="0.5"
    />
  </svg>
);
