import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import { SupplierResponse } from '../../../../../../entities/Supplier';
import { CreateSupplierInput } from '../../types/createSupplier';

export const createSupplier = createAsyncThunk<
  SupplierResponse,
  CreateSupplierInput,
  ThunkConfig<string>
>('supplier/createSupplier', async (input, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const response = await $api.post<SupplierResponse>('/suppliers', input);

    dispatch(
      openSnackbar({
        open: true,
        message: 'Поставщик создан',
        variant: 'alert',
        alert: {
          color: 'success',
        },
      }),
    );

    return response.data;
  } catch (err) {
    const errorText = `Ошибка при создании поставщика: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
