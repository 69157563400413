import { RootState } from '../../../../app/deprecated/store/store';
import { createSelector } from '@reduxjs/toolkit';
import { SupplierOrderItem } from '../types/supplierOrderItem';

export const getSupplierOrdersItemsByOrderLoading = (state: RootState) =>
  state.supplierOrdersItemsByOrder.loading;

export const getSuppliersOrders = (state: RootState) => state.supplierOrdersItemsByOrder.orders;

export const getSuppliersOrdersItemsMap = createSelector(
  (state: RootState) => state.supplierOrdersItemsByOrder.orderItems,
  (orderItems) =>
    orderItems.reduce((acc, item) => {
      if (!acc.has(item.supplierOrderId)) {
        acc.set(item.supplierOrderId, []);
      }
      acc.get(item.supplierOrderId)?.push(item);
      return acc;
    }, new Map() as Map<string, SupplierOrderItem[]>),
);

export const getSupplierOrdersItemsByOrder = createSelector(
  [getSuppliersOrders, getSuppliersOrdersItemsMap],
  (orders, ordersItemMap) =>
    orders.map((order) => ({
      ...order,
      orderItems: ordersItemMap.get(order.id),
    })),
);
