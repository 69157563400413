import { FC, Suspense } from 'react';

interface LazyComponentProps {}

export const LazyComponent = (Component: FC<{}>) => (props: LazyComponentProps) => {
  return (
    <Suspense fallback={''}>
      <Component {...props} />
    </Suspense>
  );
};
