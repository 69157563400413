import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from 'app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from 'app/deprecated/store/store';
import $api from 'shared/api/api';
import { EmailResponse } from '../../types/email';

export const deleteEmail = createAsyncThunk<EmailResponse, string, ThunkConfig<string>>(
  'email/deleteEmail',
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.delete<EmailResponse>(`/email/${id}`);

      dispatch(
        openSnackbar({
          open: true,
          message: 'Email удален',
          variant: 'alert',
          alert: {
            color: 'success',
          },
        }),
      );

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при удалении email: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
