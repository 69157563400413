import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import { SupplierResponse } from '../../../../../../entities/Supplier';

export const fetchSupplier = createAsyncThunk<SupplierResponse, string, ThunkConfig<string>>(
  'supplier/fetchSupplier',
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.get<SupplierResponse>(`/supplier/${id}`);

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при загрузке поставщика: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
