import { memo, ReactNode } from 'react';
import { Button as MuiButton, ButtonProps } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';

const size = 40;

interface MenuButtonProps extends ButtonProps {
  icon: ReactNode;
}

const Button = styled(MuiButton, { shouldForwardProp: (prop) => prop !== 'icon' })(
  ({ theme }): CSSObject => ({
    minWidth: size,
    width: size,
    height: size,
    fontSize: size,
    borderRadius: 10,
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: theme.palette.hover.main,
    },
  }),
);

export const MenuButton = memo((props: MenuButtonProps) => {
  const { icon, ...otherProps } = props;

  return (
    <Button variant="outlined" color="border" {...otherProps}>
      {icon}
    </Button>
  );
});
