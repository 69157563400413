import { AxiosResponseHeaders } from 'axios';

export const DEFAULT_FILE_NAME = 'file';

export const getFileNameFromHeaders = (headers: AxiosResponseHeaders) => {
  const RU_FILENAME_PREFIX = "filename*=UTF-8''";
  const ENG_FILENAME_PREFIX = 'filename=';

  const contentDisposition = (headers['content-disposition'] ?? '').split(';');

  const ruFileName = contentDisposition.filter((item) =>
    item.trim().startsWith(RU_FILENAME_PREFIX),
  )?.[0];

  if (ruFileName) {
    return decodeURI(ruFileName.trim().replaceAll(RU_FILENAME_PREFIX, ''));
  }

  const engFileName = contentDisposition.filter((item) =>
    item.trim().startsWith(ENG_FILENAME_PREFIX),
  )?.[0];

  if (engFileName) {
    return engFileName.trim().replaceAll(new RegExp(`"|${ENG_FILENAME_PREFIX}`, 'g'), '');
  }

  return DEFAULT_FILE_NAME;
};
