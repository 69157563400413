import { AxiosResponse } from 'axios';
import $api from '../api/api';
import { FetchUsersInput, UsersResponse } from '../../pages/deprecated/Users/model/types/users';
import { UserResponse } from '../../entities/User';
import { CreateUserInput } from '../../entities/User/model/types/user';

export class UserService {
  static async getUsers(input?: FetchUsersInput): Promise<AxiosResponse<UsersResponse>> {
    const params: FetchUsersInput = {
      sort: input?.sort ?? `["name", "DESC"]`,
    };

    if (input?.range) {
      params.range = input.range;
    }

    if (input?.sort) {
      params.sort = input.sort;
    }

    if (input?.filter) {
      params.filter = input.filter;
    }

    return $api.get<UsersResponse>('/users', { params });
  }

  static async getUser(id: string): Promise<AxiosResponse<UserResponse>> {
    return $api.get<UserResponse>(`/user/${id}`);
  }

  static async createUser(input: CreateUserInput): Promise<AxiosResponse<UserResponse>> {
    return $api.post<UserResponse>('/users', input);
  }
}
