import React, { memo, useCallback, useState } from 'react';
import { getStopJobLoading } from '../model/selectors/stopJobSelectors';
import { stopJob } from '../model/services/stopJob/stopJob';
import { StopOutlined } from '@ant-design/icons';
import { Tooltip } from '@mui/material';
import IconButton from '../../../../shared/ui/deprecated/@extended/IconButton';
import AlertDialog from '../../../../shared/ui/deprecated/Modal/AlertDialog';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';

interface StopJobButtonProps {
  className?: string;
  jobId: string;
}

export const StopJobButton = memo((props: StopJobButtonProps) => {
  const { className, jobId } = props;

  const [alertOpened, setAlertOpened] = useState(false);
  const loading = useAppSelector(getStopJobLoading);
  const dispatch = useAppDispatch();

  const handleOpenDialog = () => {
    setAlertOpened(true);
  };

  const handleCloseDialog = () => {
    setAlertOpened(false);
  };

  const handleStopJob = useCallback(() => {
    dispatch(stopJob(jobId));
    handleCloseDialog();
  }, [dispatch, jobId]);

  return (
    <>
      <AlertDialog
        open={alertOpened}
        confirmText={'Вы действительно хотите остановить загрузку?'}
        onClose={handleCloseDialog}
        onConfirm={handleStopJob}
        confirmButtonText={'Остановить'}
      />
      <Tooltip title="Остановить загрузку">
        <IconButton
          className={className}
          color="error"
          onClick={handleOpenDialog}
          disabled={loading}
        >
          <StopOutlined />
        </IconButton>
      </Tooltip>
    </>
  );
});
