import React, { memo } from 'react';
import cls from './SupplierOrderInfo.module.scss';
import MainCard from '../../../../shared/ui/deprecated/MainCard';
import { formatPrice } from '../../../../shared/lib/utils/formatting/formatPrice/formatPrice';
import { Divider, List, Stack } from '@mui/material';
import { OrderInfoListItem } from './OrderInfoListItem';
import { DATE_FORMAT, getDateString } from '../../../../shared/lib/utils/formatting/formatDate';
import { getSupplierOrderFormData } from '../../model/selectors/supplierOrderFormSelectors';
import { getSupplierOrderItemsList } from '../../../SupplierOrderItem';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';

interface SupplierOrderInfoProps {
  className?: string;
}

export const SupplierOrderInfo = memo((props: SupplierOrderInfoProps) => {
  const { className } = props;

  const formData = useAppSelector(getSupplierOrderFormData);
  const orderItems = useAppSelector(getSupplierOrderItemsList);

  if (!formData) {
    return null;
  }

  return (
    <Stack gap={2} className={className}>
      <MainCard title="Стоимость">
        <div className={cls.priceItem}>
          <div className={cls.priceItemTitle}>{`Всего ${orderItems?.length ?? 0} товаров`}</div>
          <div>{formatPrice(formData.totalSumm)}</div>
        </div>
        <div className={cls.priceItem}>
          <div className={cls.priceItemTitle}>{'Дисконт'}</div>
          <div>{formatPrice(formData.totalDiscount)}</div>
        </div>
      </MainCard>
      <MainCard title="Информация">
        <List>
          <OrderInfoListItem
            title={'Создан'}
            text={getDateString(formData.rcrmCreatedAt, DATE_FORMAT)}
          />
          <Divider component="li" />
          <OrderInfoListItem title={'Меблировка'} text={formData.furnishing} />
          <Divider component="li" />
          <OrderInfoListItem
            title={'Дата доставки'}
            text={`с ${getDateString(formData.installDtFrom, DATE_FORMAT)} по ${getDateString(
              formData.installDtTo,
              DATE_FORMAT,
            )}`}
          />
        </List>
      </MainCard>
      <MainCard title="Клиент">
        <List>
          <OrderInfoListItem
            title={'ФИО'}
            text={`${formData.clientData.firstName} ${formData.clientData.lastName}`}
          />
          <Divider component="li" />
          <OrderInfoListItem title={'Телефон'} text={formData.clientData.phone} />
          <Divider component="li" />
          <OrderInfoListItem title={'E-mail'} text={formData.clientData.email} />
          <Divider component="li" />
          <OrderInfoListItem title={'Адрес доставки'} text={formData.deliveryAddress} />
        </List>
      </MainCard>
    </Stack>
  );
});
