import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { camelCaseObject } from '../../../../../shared/lib/utils/formatting/formatResponse';
import { SupplierItem } from '../../../../../entities/SupplierItem';
import { fetchSupplierItem } from '../services/fetchSupplierItem/fetchSupplierItem';

interface SupplierItemsState {
  data?: SupplierItem;
  loading: boolean;
}

const initialState: SupplierItemsState = {
  data: undefined,
  loading: false,
};

export const supplierItemFormSlice = createSlice({
  name: 'supplierItemFormSlice',
  initialState,
  reducers: {
    suppliersItemsDisabledSuccess(state, { payload }: PayloadAction<boolean>) {
      if (state.data) {
        state.data.disabled = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupplierItem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSupplierItem.fulfilled, (state, action) => {
      state.loading = false;
      state.data = camelCaseObject(action.payload) as SupplierItem;
    });
    builder.addCase(fetchSupplierItem.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { actions: supplierItemFormActions } = supplierItemFormSlice;
export const { reducer: supplierItemFormReducer } = supplierItemFormSlice;
