import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import { FetchSuppliersInput, SuppliersResponse } from '../../types/suppliers';

export const fetchSuppliers = createAsyncThunk<
  SuppliersResponse,
  FetchSuppliersInput | undefined,
  ThunkConfig<string>
>('suppliers/fetchSuppliers', async (input, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const params: FetchSuppliersInput = {
      sort: input?.sort ?? `["created_at", "DESC"]`,
    };

    if (input?.range) {
      params.range = input.range;
    }

    if (input?.sort) {
      params.sort = input.sort;
    }

    if (input?.search) {
      params.search = input.search;
    }

    if (input?.filter) {
      params.filter = input.filter;
    }

    const response = await $api.get<SuppliersResponse>('/suppliers', { params: params });

    return response.data;
  } catch (err) {
    const errorText = `Ошибка при загрузке поставщиков: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
