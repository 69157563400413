import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import BackLink from '../../../../shared/ui/deprecated/BackLink/BackLink';
import MainCard from '../../../../shared/ui/deprecated/MainCard';
import { UserForm } from '../../../../entities/User';
import { getRouteUsers } from '../../../../shared/const/router';
import ThemeCustomization from '../../../../app/deprecated/themes';

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
export const UserViewForm: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleEditButton = () => {
    navigate('edit');
  };

  return (
    <ThemeCustomization>
      <Stack>
        <BackLink href={getRouteUsers()} title={'К списку пользователей'} />
        <MainCard
          title={'Пользователь'}
          secondary={
            <Button variant="contained" onClick={handleEditButton}>
              {'Редактировать пользователя'}
            </Button>
          }
        >
          <UserForm userId={id} viewOnly />
        </MainCard>
      </Stack>
    </ThemeCustomization>
  );
};
