import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../app/deprecated/store/store';
import $api from '../../../../../shared/api/api';
import { LinksResponse } from '../../types/links';

export const fetchLinks = createAsyncThunk<LinksResponse, void, ThunkConfig<string>>(
  'link/fetchLinks',
  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.get<LinksResponse>('/links', {
        params: { sort: `["created_at", "DESC"]` },
      });

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при загрузке ссылок: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
