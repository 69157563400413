import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface MainLayoutProps {
  drawer: ReactElement;
  content: ReactElement;
}

const BoxSx: SxProps<Theme> = {
  flexGrow: 1,
  height: '100vh',
  minWidth: '1px',
  backgroundColor: (theme) => theme.palette.grayBg.main,
};

export const MainLayout = (props: MainLayoutProps) => {
  const { drawer, content } = props;

  return (
    <Box display="flex" width="100%">
      {drawer}
      <Box component="main" sx={BoxSx}>
        {content}
      </Box>
    </Box>
  );
};
