import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';

interface MakeBriefForSupplierInput {
  supplierId: string;
  replace?: boolean;
}

export const makeBriefForSupplier = createAsyncThunk<
  void,
  MakeBriefForSupplierInput,
  ThunkConfig<string>
>('supplier/makeBriefForSupplier', async ({ supplierId, replace }, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    if (replace) {
      await $api.post(`/supplier/${supplierId}/replace_brief`);
    } else {
      await $api.post(`/supplier/${supplierId}/brief`);
    }

    dispatch(
      openSnackbar({
        open: true,
        message: 'Товары обновлены',
        variant: 'alert',
        alert: {
          color: 'success',
        },
      }),
    );
  } catch (err) {
    const errorText = `Ошибка при обновлении товаров: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
