import React, { FC, memo, useCallback } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { SxProps } from '@mui/system';
import { Button, Theme } from '@mui/material';
import { loadToExcel } from '../lib/loadToExcel';

interface ExcelExportButtonProps {
  data: any[];
  title?: string;
  filename: string;
  className?: string;
  variant?: 'text' | 'outlined' | 'contained' | 'dashed' | 'shadow' | 'light';
  buttonSx?: SxProps<Theme>;
  size?: 'small' | 'medium' | 'large' | 'extraSmall';
  color?: 'secondary' | 'inherit' | 'primary' | 'success' | 'error' | 'info' | 'warning';
}

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
export const ExcelExportButton: FC<ExcelExportButtonProps> = memo((props) => {
  const { data, title = 'Excel', filename, className, variant, size, color, buttonSx } = props;

  const handleClick = useCallback(() => {
    loadToExcel(data, filename);
  }, [data, filename]);

  return (
    <Button
      variant={variant}
      color={color}
      sx={buttonSx}
      size={size}
      startIcon={<DownloadOutlined />}
      className={className}
      onClick={handleClick}
    >
      {title}
    </Button>
  );
});
