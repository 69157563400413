import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import { FetchUsersInput, UsersResponse } from '../../types/users';
import $api from '../../../../../../shared/api/api';

export const fetchUsers = createAsyncThunk<
  UsersResponse,
  FetchUsersInput | undefined,
  ThunkConfig<string>
>('users/fetchUsers', async (input, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const params: FetchUsersInput = {
      sort: input?.sort ?? `["name", "DESC"]`,
    };

    if (input?.range) {
      params.range = input.range;
    }

    if (input?.sort) {
      params.sort = input.sort;
    }

    if (input?.search) {
      params.search = input.search;
    }

    if (input?.filter) {
      params.filter = input.filter;
    }

    const response = await $api.get<UsersResponse>('/users', { params });

    return response.data;
  } catch (err) {
    const errorText = `Ошибка при загрузке пользователей: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
