import { NavigationItem } from '../types/navigationItem';
import { RolesEnum } from '../../../../../shared/lib/utils/constants/commonConstants';
import { ReactComponent as UploadIcon } from 'shared/assets/icons/redesigned/upload.svg';
import { ReactComponent as UploadedIcon } from 'shared/assets/icons/redesigned/uploaded.svg';
import { ReactComponent as SuppliersIcon } from 'shared/assets/icons/redesigned/suppliers.svg';
import { ReactComponent as ItemsListIcon } from 'shared/assets/icons/redesigned/items-list.svg';
import { ReactComponent as CommentsIcon } from 'shared/assets/icons/redesigned/comments.svg';
import { ReactComponent as OrdersIcon } from 'shared/assets/icons/redesigned/orders.svg';
import { ReactComponent as UsersIcon } from 'shared/assets/icons/redesigned/users.svg';
import {
  getRouteJobs,
  getRouteSuppliers,
  getRouteSuppliersComments,
  getRouteSuppliersItems,
  getRouteSuppliersOrders,
  getRouteUploadCatalogTab,
  getRouteUsers,
} from '../../../../../shared/const/router';

export const navigationItems: NavigationItem[] = [
  {
    title: 'Загрузка товаров',
    url: getRouteUploadCatalogTab('single'),
    icon: UploadIcon,
    roles: [RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker],
  },
  {
    title: 'Журнал загрузок',
    url: getRouteJobs(),
    icon: UploadedIcon,
    roles: [RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker],
  },
  {
    title: 'Поставщики',
    url: getRouteSuppliers(),
    icon: SuppliersIcon,
    roles: [RolesEnum.Admin],
  },
  {
    title: 'Товары',
    url: getRouteSuppliersItems(),
    icon: ItemsListIcon,
    roles: [RolesEnum.Admin],
  },
  {
    title: 'Комментарии',
    url: getRouteSuppliersComments(),
    icon: CommentsIcon,
    roles: [RolesEnum.Admin],
  },
  {
    title: 'Заказы',
    url: getRouteSuppliersOrders(),
    icon: OrdersIcon,
    roles: [RolesEnum.Admin, RolesEnum.Owner, RolesEnum.Worker],
  },
  {
    title: 'Пользователи',
    url: getRouteUsers(),
    icon: UsersIcon,
    roles: [RolesEnum.Admin],
  },
];
