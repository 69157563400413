import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../../shared/lib/hooks/deprecated/useAuth';
import { RolesEnum } from '../../../shared/lib/utils/constants/commonConstants';
import { LinearProgress } from '@mui/material';
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector';

interface ProtectedRouteProps {
  roles: RolesEnum[];
}

const ProtectedRoute: React.FC<React.PropsWithChildren<ProtectedRouteProps>> = ({
  children,
  roles,
}) => {
  const { user } = useAuth();
  const location = useLocation();
  const { commonDataLoading } = useAppSelector((state) => state.commonData);

  if (commonDataLoading) {
    return <LinearProgress color="primary" />;
  }

  if (!roles.includes(user?.role as RolesEnum)) {
    return <Navigate to="/unauthorized" replace={true} state={{ from: location }} />;
  }
  return <>{children}</>;
};

export default ProtectedRoute;
