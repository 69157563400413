import React, { memo, ReactElement } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import { FormControl, InputLabel, ListSubheader } from '@mui/material';
import { SelectOption } from '../../../types/common';

export interface GroupedSelectProps extends OutlinedTextFieldProps {
  id: string;
  groupedOptions: Record<string, SelectOption[]>;
  label: string;
  className?: string;
}

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
export const GroupedSelect: React.FC<GroupedSelectProps> = memo((props: GroupedSelectProps) => {
  const { id, groupedOptions, label, className, value, error, disabled, ...otherProps } = props;

  const optionsItems = React.useMemo(() => {
    const items: ReactElement[] = [];

    Object.entries(groupedOptions).forEach(([groupName, options]) => {
      items.push(<ListSubheader key={groupName}>{groupName}</ListSubheader>);
      items.push(
        ...options.map(({ label, value, isDisabled }) => (
          <MenuItem key={value} value={value} disabled={isDisabled}>
            {label}
          </MenuItem>
        )),
      );
    });

    return items;
  }, [groupedOptions]);

  return (
    <FormControl variant="outlined" className={className} fullWidth>
      <InputLabel htmlFor={id} shrink={!!`${value}`} error={error} disabled={disabled}>
        {label}
      </InputLabel>
      <TextField
        id={id}
        select
        fullWidth
        error={error}
        value={value}
        disabled={disabled}
        {...otherProps}
      >
        <MenuItem value={''}>
          <em>Не выбрано</em>
        </MenuItem>
        {optionsItems}
      </TextField>
    </FormControl>
  );
});
