import { createSelector } from '@reduxjs/toolkit';
import { getAuthUser } from '../../../../../features/AuthByEmail';
import { navigationItems } from '../consts/navigationItems';

export const getNavigationItems = createSelector([getAuthUser], (authUser) => {
  if (!authUser) {
    return [];
  }
  return navigationItems.filter((item) => item.roles.includes(authUser.role));
});
