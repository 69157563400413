import { useCallback, useState } from 'react';

interface UseModalOptions {
  defaultOpen?: boolean;
  confirmCallback?: () => void;
}

export function useModal(options?: UseModalOptions) {
  const { defaultOpen = false, confirmCallback } = options ?? {};

  const [open, setOpen] = useState(defaultOpen);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onConfirm = useCallback(() => {
    onClose();
    confirmCallback?.();
  }, [onClose, confirmCallback]);

  return {
    open,
    onOpen,
    onClose,
    onConfirm,
  };
}
