import { createSlice } from '@reduxjs/toolkit';
import {
  camelCaseArray,
  camelCaseObject,
} from '../../../../../shared/lib/utils/formatting/formatResponse';
import { fetchSupplierComments } from '../services/fetchSupplierComments/fetchSupplierComments';
import { SupplierComment } from '../../../../../entities/SupplierComment';
import { User } from '../../../../../entities/User';
import { createSupplierComment } from '../services/createSupplierComment/createSupplierComment';

interface SupplierCommentsState {
  list?: SupplierComment[];
  loading: boolean;
  users?: User[];
}

const initialState: SupplierCommentsState = {
  list: undefined,
  loading: false,
  users: undefined,
};

export const supplierCommentsSlice = createSlice({
  name: 'supplierCommentsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchSupplierComments
    builder.addCase(fetchSupplierComments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSupplierComments.fulfilled, (state, action) => {
      state.loading = false;
      state.list = camelCaseArray(action.payload.data) as SupplierComment[];
      state.users = camelCaseArray(action.payload.data_users) as User[];
    });
    builder.addCase(fetchSupplierComments.rejected, (state) => {
      state.loading = false;
    });
    // createSupplierComment
    builder.addCase(createSupplierComment.fulfilled, (state, action) => {
      state.list?.unshift(camelCaseObject(action.payload) as SupplierComment);
    });
  },
});

export const { actions: supplierCommentsActions } = supplierCommentsSlice;
export const { reducer: supplierCommentsReducer } = supplierCommentsSlice;
