import { AppDispatch, RootState } from '../store';
import { SuppliersService } from '../../../../shared/services/SuppliersService';
import { setCommonDataLoading, setSuppliers, setUsers } from '../reducers/commonDataSlice';
import { camelCaseArray } from '../../../../shared/lib/utils/formatting/formatResponse';
import { openSnackbar } from '../reducers/snackbarSlice';
import { RolesEnum } from '../../../../shared/lib/utils/constants/commonConstants';
import { UserService } from '../../../../shared/services/UserService';
import { User } from '../../../../entities/User';
import { Supplier } from '../../../../entities/Supplier';

export const fetchCommonData = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const { auth } = getState();

    dispatch(setCommonDataLoading(true));

    if (auth.user?.role === RolesEnum.Admin) {
      const suppliersResponse = await SuppliersService.getSuppliers({ sort: '["name", "ASC"]' });
      dispatch(setSuppliers(camelCaseArray(suppliersResponse.data.data) as Supplier[]));

      const usersResponse = await UserService.getUsers();
      dispatch(setUsers(camelCaseArray(usersResponse.data.data) as User[]));
    }

    dispatch(setCommonDataLoading(false));
  } catch (err) {
    dispatch(setCommonDataLoading(false));
    const errorText = `Ошибка при загрузке данных приложенния: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );
  }
};
