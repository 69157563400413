import { createSlice } from '@reduxjs/toolkit';
import { camelCaseArray } from '../../../../../shared/lib/utils/formatting/formatResponse';
import { Supplier } from '../../../../../entities/Supplier';
import { fetchSuppliers } from '../services/fetchSuppliers/fetchSuppliers';
import { loadToExcel } from '../../../../../shared/ui/deprecated/ExcelExportButton';

interface SuppliersState {
  list: Supplier[];
  totalCount: number;
  loading: boolean;
  listForExcelLoading: boolean;
}

const initialState: SuppliersState = {
  list: [],
  totalCount: 0,
  loading: false,
  listForExcelLoading: false,
};

export const suppliersSlice = createSlice({
  name: 'suppliersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliers.pending, (state, action) => {
      if (action.meta.arg?.forExcel) {
        state.listForExcelLoading = true;
        return;
      }
      state.loading = true;
    });
    builder.addCase(fetchSuppliers.fulfilled, (state, action) => {
      if (action.meta.arg?.forExcel) {
        loadToExcel(action.payload.data, 'suppliers');
        state.listForExcelLoading = false;
        return;
      }
      state.loading = false;
      state.list = camelCaseArray(action.payload.data) as Supplier[];
      state.totalCount = action.payload.total;
    });
    builder.addCase(fetchSuppliers.rejected, (state, action) => {
      if (action.meta.arg?.forExcel) {
        state.listForExcelLoading = false;
        return;
      }
      state.loading = false;
    });
  },
});

export const { actions: suppliersActions } = suppliersSlice;
export const { reducer: suppliersReducer } = suppliersSlice;
