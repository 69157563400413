import { DatabaseIcon } from '../../../../shared/ui/deprecated/icons/DatabaseIcon';
import { PartIcon } from '../../../../shared/ui/deprecated/icons/PartIcon';
import { PriceIcon } from '../../../../shared/ui/deprecated/icons/PriceIcon';

export enum ContentTypeEnum {
  Catalog = 'catalog',
  CatalogReplaceNoImg = 'catalog_replace_no_img',
  CatalogReplaceWithImg = 'catalog_replace_with_img',
  CatalogReplaceOnlyImg = 'catalog_replace_only_img',
  Qnt = 'qnt',
  Price = 'price',
}

export const ContentTypeRuMap = {
  [ContentTypeEnum.Catalog]: 'Стандартная загрузка каталога',
  [ContentTypeEnum.CatalogReplaceNoImg]: 'Жесткая замена каталога без картинок',
  [ContentTypeEnum.CatalogReplaceOnlyImg]: 'Жесткая замена картинок в каталоге',
  [ContentTypeEnum.CatalogReplaceWithImg]: 'Жесткая замена каталога и картинок',
  [ContentTypeEnum.Qnt]: 'Остатки',
  [ContentTypeEnum.Price]: 'Цены',
};

export const contentTypeOptions = Object.values(ContentTypeEnum).map((item) => ({
  value: item,
  label: ContentTypeRuMap[item],
}));

export const contentTypeGroupedOptions = {
  Каталог: [
    { label: ContentTypeRuMap[ContentTypeEnum.Catalog], value: ContentTypeEnum.Catalog },
    {
      label: ContentTypeRuMap[ContentTypeEnum.CatalogReplaceNoImg],
      value: ContentTypeEnum.CatalogReplaceNoImg,
    },
    {
      label: ContentTypeRuMap[ContentTypeEnum.CatalogReplaceOnlyImg],
      value: ContentTypeEnum.CatalogReplaceOnlyImg,
    },
    {
      label: ContentTypeRuMap[ContentTypeEnum.CatalogReplaceWithImg],
      value: ContentTypeEnum.CatalogReplaceWithImg,
    },
  ],
  Остатки: [{ label: ContentTypeRuMap[ContentTypeEnum.Qnt], value: ContentTypeEnum.Qnt }],
  Цены: [{ label: ContentTypeRuMap[ContentTypeEnum.Price], value: ContentTypeEnum.Price }],
};

export enum JobStatusEnum {
  New = 'new',
  Preparation = 'preparation',
  Progress = 'progress',
  Processed = 'processed',
}

export enum JobResultEnum {
  New = 'new',
  Completed = 'completed',
  Warning = 'warning',
  Error = 'error',
}

export const JobResultRuMap = {
  [JobResultEnum.New]: 'Новый',
  [JobResultEnum.Completed]: 'Завершено',
  [JobResultEnum.Warning]: 'Предупреждение',
  [JobResultEnum.Error]: 'Ошибка',
};

export const jobResultOptions = Object.values(JobResultEnum).map((item) => ({
  value: item,
  label: JobResultRuMap[item],
}));

export enum CreatorEnum {
  Link = 'link',
  User = 'user',
  Email = 'email',
}

export const contentTypeIconMap = {
  [ContentTypeEnum.Catalog]: DatabaseIcon,
  [ContentTypeEnum.CatalogReplaceWithImg]: DatabaseIcon,
  [ContentTypeEnum.CatalogReplaceOnlyImg]: DatabaseIcon,
  [ContentTypeEnum.CatalogReplaceNoImg]: DatabaseIcon,
  [ContentTypeEnum.Price]: PriceIcon,
  [ContentTypeEnum.Qnt]: PartIcon,
};
