import { SelectOption } from '../../../../../shared/types/common';

export enum ArticlesType {
  Articles = 'articles',
  SupplierArticles = 'supplierArticles',
}

export const articlesTypeOptions: SelectOption[] = [
  { value: ArticlesType.Articles, label: 'Внутренний артикул' },
  { value: ArticlesType.SupplierArticles, label: 'Артикул поставщика' },
];

export enum InputType {
  File = 'file',
  Text = 'text',
}

export const inputTypeOptions: SelectOption[] = [
  { value: InputType.File, label: 'Загрузить файлом' },
  { value: InputType.Text, label: 'Указать текстом' },
];
