import { createSlice } from '@reduxjs/toolkit';
import { camelCaseArray } from '../../../../shared/lib/utils/formatting/formatResponse';
import { fetchSupplierOrderItems } from '../services/fetchSupplierOrderItems/fetchSupplierOrderItems';
import { SupplierOrderItem } from '../types/supplierOrderItem';

interface SupplierOrderItemsState {
  list: SupplierOrderItem[];
  totalCount: number;
  loading: boolean;
}

const initialState: SupplierOrderItemsState = {
  list: [],
  totalCount: 0,
  loading: false,
};

export const supplierOrderItemsSlice = createSlice({
  name: 'supplierOrderItemsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSupplierOrderItems.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSupplierOrderItems.fulfilled, (state, action) => {
      state.loading = false;
      state.list = camelCaseArray(action.payload.data) as SupplierOrderItem[];
      state.totalCount = action.payload.total;
    });
    builder.addCase(fetchSupplierOrderItems.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { actions: supplierOrderItemsActions } = supplierOrderItemsSlice;
export const { reducer: supplierOrderItemsReducer } = supplierOrderItemsSlice;
