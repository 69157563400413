import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { camelCaseObject } from 'shared/lib/utils/formatting/formatResponse';
import { User } from '../types/user';
import { fetchUser } from '../services/fetchUser/fetchUser';
import { createUser } from '../services/createUser/createUser';
import { updateUser } from '../services/updateUser/updateUser';

interface EmailFormState {
  data?: User;
  loading: boolean;
  updateLoading: boolean;
  createLoading: boolean;
}

const initialState: EmailFormState = {
  data: undefined,
  loading: false,
  updateLoading: false,
  createLoading: false,
};

export const userFormSlice = createSlice({
  name: 'userFormSlice',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<User>) {
      state.data = action.payload;
    },
    resetData(state) {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    // fetchEmail
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = camelCaseObject(payload) as User;
    });
    builder.addCase(fetchUser.rejected, (state) => {
      state.loading = false;
    });
    // createLink
    builder.addCase(createUser.pending, (state) => {
      state.createLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.createLoading = false;
      state.data = camelCaseObject(payload) as User;
    });
    builder.addCase(createUser.rejected, (state) => {
      state.createLoading = false;
    });
    // updateEmail
    builder.addCase(updateUser.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.updateLoading = false;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.updateLoading = false;
    });
  },
});

export const { actions: userFormActions } = userFormSlice;
export const { reducer: userFormReducer } = userFormSlice;
