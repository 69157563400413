// material-ui
import { styled } from '@mui/material/styles';

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
const ScrollX = styled('div')({
  width: '100%',
  overflowX: 'auto',
  display: 'block',
});

export default ScrollX;
