import { RootState } from '../../../../app/deprecated/store/store';
import { createSelector } from '@reduxjs/toolkit';
import { getSuppliersMap } from '../../../../shared/lib/utils/selectors/commonDataSelectors';

export const getSupplierOrderFormData = createSelector(
  [(state: RootState) => state.supplierOrderForm.data, getSuppliersMap],
  (data, suppliersMap) => {
    if (!data) {
      return data;
    }

    return {
      ...data,
      orderNumber: `${data.rcrmNumber}-${suppliersMap?.get(data.supplierId)?.code}`,
      furnishing: data.orderData?.Meblirovka === 'true' ? 'Да' : 'Нет',
      residentialComplex: data.orderData?.Jk,
    };
  },
);

export const getSupplierOrderFormLoading = (state: RootState) => state.supplierOrderForm.loading;
