// main
export const getRouteMain = () => '/';
export const getRouteUploadCatalogTab = (tab: string) => `/upload-catalog/${tab}`;
export const getRouteUploadCatalogLink = (id: string) => `/upload-catalog/link/${id}`;
export const getRouteUploadCatalogLinkEdit = (id: string) => `/upload-catalog/link/${id}/edit`;
export const getRouteUploadCatalogEmail = (id: string) => `/upload-catalog/email/${id}`;
export const getRouteUploadCatalogEmailEdit = (id: string) => `/upload-catalog/email/${id}/edit`;
export const getRouteJobs = () => '/jobs';
export const getRouteJobDetails = (id: string) => `/jobs/${id}`;
export const getRouteSuppliers = () => '/suppliers';
export const getRouteSupplierTab = (id: string, tab: string) => `/suppliers/${id}/${tab}`;
export const getRouteSupplierCreate = () => '/suppliers/new';
export const getRouteSupplierEdit = (id: string) => `/suppliers/${id}/edit`;
export const getRouteSuppliersItems = () => '/supplierItems';
export const getRouteSuppliersItemDetails = (id: string) => `/supplierItems/${id}`;
export const getRouteSuppliersComments = () => '/suppliersComments';
export const getRouteUsers = () => '/users';
export const getRouteUserCreate = () => '/users/new';
export const getRouteUserDetails = (id: string) => `/users/${id}`;
export const getRouteUserEdit = (id: string) => `/users/${id}/edit`;
export const getRouteSuppliersOrders = () => '/suppliersOrders';
export const getRouteSuppliersOrderDetails = (id: string) => `/suppliersOrders/${id}`;
export const getRouteDisableSuppliersItems = () => '/disableSuppliersItems';

// unauthorized
export const getRouteUnauthorized = () => '/unauthorized';

// maintenance
export const getRouteMaintenance = () => '/maintenance';
export const getRoute400 = () => '/maintenance/400';
export const getRoute500 = () => '/maintenance/500';
export const getRouteUnderConstruction = () => '/maintenance/under-construction';

// login
export const getRouteLogin = () => '/login';
