import React, { memo } from 'react';
import {
  ContentTypeEnum,
  contentTypeIconMap,
  ContentTypeRuMap,
} from '../../model/lib/jobsConstants';
import { Tooltip } from '@mui/material';

interface JobContentProps {
  contentType: string;
}

export const JobContent = memo((props: JobContentProps) => {
  const { contentType } = props;

  const ContentTypeIcon = contentTypeIconMap[contentType as ContentTypeEnum];

  return (
    <Tooltip title={ContentTypeRuMap[contentType as ContentTypeEnum] || ''}>
      <div>{ContentTypeIcon ? <ContentTypeIcon /> : contentType}</div>
    </Tooltip>
  );
});
