/*
  Массив labels: [1, 2, 6]
  Например: ['день', 'дня', 'дней']
*/

const getCardinalLabel = (count: number, labels: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return `${
    labels[count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]]
  }`;
};

export const getCardinalNumberString = (count: number, labels: string[]): string => {
  const cardinalLabel = getCardinalLabel(count, labels);
  return `${count} ${cardinalLabel}`;
};
