import React, { lazy } from 'react';

// project import
import GuestGuard from 'shared/lib/utils/route-guard/GuestGuard';
import CommonLayout from 'shared/ui/deprecated/Layout/CommonLayout';
import Loadable from 'shared/ui/deprecated/Loadable';
import { getRouteLogin, getRouteMain } from '../../../shared/const/router';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/deprecated/Login/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: getRouteMain(),
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: getRouteMain(),
          element: <AuthLogin />,
        },
        {
          path: getRouteLogin(),
          element: <AuthLogin />,
        },
      ],
    },
  ],
};

export default LoginRoutes;
