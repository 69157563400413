import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from 'app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from 'app/deprecated/store/store';
import $api from 'shared/api/api';
import { UpdateUserInput, UserResponse } from '../../types/user';
import omit from 'lodash/omit';

export const updateUser = createAsyncThunk<UserResponse, UpdateUserInput, ThunkConfig<string>>(
  'user/updateUser',
  async (input, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await $api.put<UserResponse>(`/user/${input.id}`, omit(input, 'id'));

      return response.data;
    } catch (err) {
      const errorText = `Ошибка при обновлении пользователя: ${
        err instanceof Error ? err.message : String(err)
      }`;
      dispatch(
        openSnackbar({
          open: true,
          message: errorText,
          variant: 'alert',
          alert: {
            color: 'error',
          },
        }),
      );

      return rejectWithValue(errorText);
    }
  },
);
