import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { camelCaseArray } from '../../../../../shared/lib/utils/formatting/formatResponse';
import { SupplierItem } from '../../../../../entities/SupplierItem';
import { fetchSupplierItems } from '../services/fetchSupplierItems/fetchSupplierItems';
import { loadToExcel } from '../../../../../shared/ui/deprecated/ExcelExportButton';

interface SupplierItemsState {
  list: SupplierItem[];
  totalCount: number;
  loading: boolean;
  listForExcelLoading: boolean;
}

const initialState: SupplierItemsState = {
  list: [],
  totalCount: 0,
  loading: false,
  listForExcelLoading: false,
};

export const supplierItemsSlice = createSlice({
  name: 'supplierItemsSlice',
  initialState,
  reducers: {
    suppliersItemsDisabledSuccess(
      state,
      { payload }: PayloadAction<{ supplierArticles: string[]; disabled: boolean }>,
    ) {
      state.list
        .filter((item) => payload.supplierArticles.includes(item.supplierArticle))
        .forEach((item) => {
          item.disabled = payload.disabled;
        });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupplierItems.pending, (state, action) => {
      if (action.meta.arg?.forExcel) {
        state.listForExcelLoading = true;
        return;
      }
      state.loading = true;
    });
    builder.addCase(fetchSupplierItems.fulfilled, (state, action) => {
      if (action.meta.arg?.forExcel) {
        loadToExcel(action.payload.data, 'supplierItems');
        state.listForExcelLoading = false;
        return;
      }
      state.loading = false;
      state.list = camelCaseArray(action.payload.data) as SupplierItem[];
      state.totalCount = action.payload.total;
    });
    builder.addCase(fetchSupplierItems.rejected, (state, action) => {
      if (action.meta.arg?.forExcel) {
        state.listForExcelLoading = false;
        return;
      }
      state.loading = false;
    });
  },
});

export const { actions: supplierItemsActions } = supplierItemsSlice;
export const { reducer: supplierItemsReducer } = supplierItemsSlice;
