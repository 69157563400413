import { round } from 'lodash';

export const formatPrice = (number: number, currency: string | null = '₽', padEndZeros = false) => {
  if (!number) {
    return '0';
  }
  const [commonInt, restInt] = String(round(number, 2)).split('.');

  let rest = restInt ? `${restInt}` : '';

  if (padEndZeros) {
    rest = rest.padEnd(2, '0');
  }

  return `${commonInt.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}${rest ? `.${rest}` : ''} ${
    currency || ''
  }`.trim();
};
