import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import {
  SetSupplierItemsDisabledFromInput,
  SuppliersItemsDisabledApiInput,
} from '../../types/suppliersItemsDisabledTypes';
import { parseArticlesFromText } from '../../lib/parseArticlesFromText/parseArticlesFromText';
import { ArticlesType, InputType } from '../../consts/consts';
import { parseArticlesFromFile } from '../../lib/parseArticlesFromFile/parseArticlesFromFile';

export const setSupplierItemsDisabledFromInput = createAsyncThunk<
  void,
  SetSupplierItemsDisabledFromInput,
  ThunkConfig<string>
>('supplierItems/setSupplierItemsDisabledFromText', async (props, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const { recovery, articlesType, supplierId, comment, inputType, input } = props;

    const articles =
      inputType === InputType.File
        ? await parseArticlesFromFile(input as File)
        : parseArticlesFromText(input as string);

    const apiInput: SuppliersItemsDisabledApiInput = {
      recovery,
      supplier_id: supplierId,
      articles: [],
      supplier_articles: [],
      comment,
    };

    if (articlesType === ArticlesType.Articles) {
      apiInput.articles = articles;
    } else {
      apiInput.supplier_articles = articles;
    }

    await $api.post('/suppliers/items/disabled', apiInput);

    dispatch(
      openSnackbar({
        open: true,
        message: props.recovery ? 'Товары возвращены в продажу' : 'Товары сняты c продажи',
        variant: 'alert',
        alert: {
          color: 'success',
        },
      }),
    );
  } catch (err) {
    const errorText = `Ошибка при снятии с продажи товаров: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
