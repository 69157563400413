import React, { memo, useEffect, useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Checkbox, FormControlLabel, Grid, LinearProgress, TextField } from '@mui/material';

import { SelectCronSchedule } from '../../../../shared/ui/deprecated/SelectCronSchedule/SelectCronSchedule';
import LoadingButton from '../../../../shared/ui/deprecated/@extended/LoadingButton';
import { contentTypeGroupedOptions } from '../../../Job';
import { Select } from '../../../../shared/ui/deprecated/Select/Select';
import { Autocomplete } from '../../../../shared/ui/deprecated/Autocomplete/Autocomplete';
import { AuthTypeEnum, authTypeOptions } from '../../model/lib/linksConstants';
import { isAuthByHeader, isAuthByPassword } from '../../model/lib/linkUtils';
import { RolesEnum } from '../../../../shared/lib/utils/constants/commonConstants';
import { getSuppliersOptions } from '../../../../shared/lib/utils/selectors/commonDataSelectors';
import { CreateLinkInput, UpdateLinkInput } from '../../model/types/link';
import {
  getLinkFormCreateLoading,
  getLinkFormData,
  getLinkFormLoading,
  getLinkFormUpdateLoading,
} from '../../model/selectors/linkFormSelectors';
import { fetchLink } from '../../model/services/fetchLink/fetchLink';
import { updateLink } from '../../model/services/updateLink/updateLink';
import { createLink } from '../../model/services/createLink/createLink';
import { linkFormActions } from '../../model/slices/linkFormSlice';
import { GroupedSelect } from '../../../../shared/ui/deprecated/GroupedSelect/GroupedSelect';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';

interface LinkFormProps {
  linkId?: string | null;
  onApply?: () => void;
  viewOnly?: boolean;
}

interface LinkFormValues {
  active: boolean;
  supplierId: string;
  contentType: string;
  name: string;
  url: string;
  authType: string;
  username?: string;
  password?: string;
  headerKey?: string;
  headerValue?: string;
  croneSchedule: string;
}

type FormHelpers = FormikHelpers<LinkFormValues>;

export const LinkForm = memo(({ linkId, onApply, viewOnly }: LinkFormProps) => {
  const linkForm = useAppSelector(getLinkFormData);
  const linkFormLoading = useAppSelector(getLinkFormLoading);
  const linkCreateLoading = useAppSelector(getLinkFormCreateLoading);
  const linkUpdateLoading = useAppSelector(getLinkFormUpdateLoading);
  const suppliersOptions = useAppSelector(getSuppliersOptions);
  const { user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (linkId) {
      dispatch(fetchLink(linkId));
    }

    return () => {
      dispatch(linkFormActions.resetData());
    };
  }, [linkId, dispatch]);

  const validationSchema = yup.object().shape({
    contentType: yup.string().required('Выберите тип данных'),
    name: yup.string().trim().required('Укажите название'),
    url: yup.string().trim().url('Ссылка не корректна').required('Укажите ссылку'),
    authType: yup.string().required('Выберите тип авторизации'),
    username: yup.string().when('authType', {
      is: isAuthByPassword,
      then: yup.string().trim().required('Укажите логин'),
    }),
    password: yup.string().when('authType', {
      is: isAuthByPassword,
      then: yup.string().trim().required('Укажите пароль'),
    }),
    headerKey: yup.string().when('authType', {
      is: isAuthByHeader,
      then: yup.string().trim().required('Укажите header_key'),
    }),
    headerValue: yup.string().when('authType', {
      is: isAuthByHeader,
      then: yup.string().trim().required('Укажите header_value'),
    }),
    croneSchedule: yup.string().nullable().required('Выберите расписание'),
  });

  const initialValues: LinkFormValues = useMemo(() => {
    if (linkId) {
      return {
        active: linkForm?.active ?? true,
        supplierId: linkForm?.supplierId ?? '',
        contentType: linkForm?.contentTypes[0] ?? '',
        name: linkForm?.name ?? '',
        url: linkForm?.url ?? '',
        authType: linkForm?.authType ?? '',
        username: linkForm?.username ?? '',
        password: linkForm?.password ?? '',
        headerKey: linkForm?.headerKey ?? '',
        headerValue: linkForm?.headerValue ?? '',
        croneSchedule: linkForm?.schedule ?? '',
      };
    }

    return {
      active: true,
      supplierId: '',
      contentType: '',
      name: '',
      url: '',
      authType: '',
      username: '',
      password: '',
      headerKey: '',
      headerValue: '',
      croneSchedule: '',
    };
  }, [linkId, linkForm]);

  const onSubmit = (values: LinkFormValues, { resetForm }: FormHelpers) => {
    const input: CreateLinkInput | UpdateLinkInput = {
      active: values.active,
      name: values.name.trim(),
      url: values.url.trim(),
      schedule: values.croneSchedule || '',
      user_id: user?.id,
      supplier_id: values.supplierId || user?.supplierId,
      content_types: [values.contentType],
      format: 'xml',
      auth_type: values.authType,
    };

    if (values.authType === AuthTypeEnum.Basic || values.authType === AuthTypeEnum.Ftp) {
      input.username = values.username?.trim();
      input.password = values.password?.trim();
    }

    if (values.authType === AuthTypeEnum.Header) {
      input.header_key = values.headerKey?.trim();
      input.header_value = values.headerValue?.trim();
    }

    if (linkId) {
      // @ts-ignore
      input.id = linkId;
      dispatch(updateLink(input as UpdateLinkInput));
    } else {
      dispatch(createLink(input));
      resetForm();
    }

    onApply?.();
  };

  if (linkId && linkFormLoading) {
    return <LinearProgress color="primary" />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} alignItems={'flex-end'}>
            <Grid container item xs={10} spacing={2} style={{ marginTop: 2 }}>
              {linkId && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="active"
                        name="active"
                        checked={formik.values.active}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={viewOnly}
                      />
                    }
                    label={'Ссылка активна?'}
                  />
                </Grid>
              )}
              {!linkId && user?.role === RolesEnum.Admin && (
                <Grid item xs={4}>
                  <Autocomplete
                    id="supplierId"
                    name="supplierId"
                    label="Поставщик"
                    options={suppliersOptions}
                    value={formik.values.supplierId}
                    setFieldValue={formik.setFieldValue}
                    error={formik.touched.supplierId && Boolean(formik.errors.supplierId)}
                    helperText={formik.touched.supplierId && formik.errors.supplierId}
                    disabled={viewOnly}
                  />
                </Grid>
              )}
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="url"
                  name="url"
                  label="Ссылка"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.url && Boolean(formik.errors.url)}
                  helperText={formik.touched.url && formik.errors.url}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <GroupedSelect
                  id="contentType"
                  name="contentType"
                  label="Что передаем"
                  groupedOptions={contentTypeGroupedOptions}
                  value={formik.values.contentType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant={'outlined'}
                  error={formik.touched.contentType && Boolean(formik.errors.contentType)}
                  helperText={formik.touched.contentType && formik.errors.contentType}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Придумайте название"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  id="authType"
                  name="authType"
                  label="Тип авторизации"
                  options={authTypeOptions}
                  value={formik.values.authType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant={'outlined'}
                  error={formik.touched.authType && Boolean(formik.errors.authType)}
                  helperText={formik.touched.authType && formik.errors.authType}
                  disabled={viewOnly}
                />
              </Grid>
              {(formik.values.authType === AuthTypeEnum.Basic ||
                formik.values.authType === AuthTypeEnum.Ftp) && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="username"
                      name="username"
                      label="Логин"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.username && Boolean(formik.errors.username)}
                      helperText={formik.touched.username && formik.errors.username}
                      disabled={viewOnly}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      label="Пароль"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      disabled={viewOnly}
                    />
                  </Grid>
                </>
              )}
              {formik.values.authType === AuthTypeEnum.Header && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="headerKey"
                      name="headerKey"
                      label="header_key"
                      value={formik.values.headerKey}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.headerKey && Boolean(formik.errors.headerKey)}
                      helperText={formik.touched.headerKey && formik.errors.headerKey}
                      disabled={viewOnly}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="headerKey"
                      name="headerValue"
                      label="header_value"
                      value={formik.values.headerValue}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.headerValue && Boolean(formik.errors.headerValue)}
                      helperText={formik.touched.headerValue && formik.errors.headerValue}
                      disabled={viewOnly}
                    />
                  </Grid>
                </>
              )}
              <SelectCronSchedule
                id="croneSchedule"
                name="croneSchedule"
                value={formik.values.croneSchedule}
                error={formik.touched.croneSchedule && Boolean(formik.errors.croneSchedule)}
                helperText={formik.touched.croneSchedule && formik.errors.croneSchedule}
                disabled={viewOnly}
                setFieldValue={formik.setFieldValue}
              />
            </Grid>
            {!viewOnly && (
              <Grid item xs={2}>
                {linkId ? (
                  <LoadingButton
                    loading={linkUpdateLoading}
                    type="submit"
                    variant="contained"
                    loadingPosition="center"
                    fullWidth
                  >
                    {'Сохранить'}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    loading={linkCreateLoading}
                    type="submit"
                    variant="contained"
                    loadingPosition="center"
                    fullWidth
                  >
                    {'Создать'}
                  </LoadingButton>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
});
