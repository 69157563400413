import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { camelCaseArray } from 'shared/lib/utils/formatting/formatResponse';
import { Email } from '../types/email';
import { fetchEmails } from '../services/fetchEmails/fetchEmails';

interface LinksState {
  list: Email[];
  loading: boolean;
}

const initialState: LinksState = {
  list: [],
  loading: false,
};

export const emailsSlice = createSlice({
  name: 'emailsSlice',
  initialState,
  reducers: {
    addEmail: (state, { payload }: PayloadAction<Email>) => {
      state.list.unshift(payload);
    },
  },
  extraReducers: (builder) => {
    // fetchEmails
    builder.addCase(fetchEmails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEmails.fulfilled, (state, action) => {
      state.loading = false;
      state.list = camelCaseArray(action.payload.data) as Email[];
    });
    builder.addCase(fetchEmails.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { actions: emailsActions } = emailsSlice;
export const { reducer: emailsReducer } = emailsSlice;
