import React, { lazy, Suspense } from 'react';
import { DisableSupplierItemsButtonProps } from './DisableSupplierItemsButton';

export const SupplierItemsDisabledButtonLazy = lazy(() => import('./DisableSupplierItemsButton'));

export const DisableSupplierItemsButtonAsync = (props: DisableSupplierItemsButtonProps) => (
  <Suspense fallback={''}>
    <SupplierItemsDisabledButtonLazy {...props} />
  </Suspense>
);
