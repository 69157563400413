import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTable, useSortBy, Column } from 'react-table';
import { HeaderSort } from 'shared/ui/deprecated/third-party/ReactTable';

type SortingTableProps = {
  columns: Column[];
  data: any[];
  getHeaderProps: (column: Column) => void;
};

/**
 * @deprecated
 * Устарел, используем новые компоненты из папки redesigned
 */
const SortingTable = ({ columns, data, getHeaderProps }: SortingTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const sortingRow = [...rows];

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <TableCell
                {...column.getHeaderProps([
                  { className: column.className },
                  getHeaderProps(column),
                ])}
              >
                <HeaderSort column={column} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {sortingRow.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>
                  {cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SortingTable;
