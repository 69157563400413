import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../app/deprecated/store/store';

export const getSuppliersMap = createSelector(
  (state: RootState) => state.commonData.suppliers,
  (suppliers) =>
    suppliers.reduce((map, supplier) => {
      map.set(supplier.id, supplier);
      return map;
    }, new Map()),
);

export const getSuppliersOptions = createSelector(
  (state: RootState) => state.commonData.suppliers,
  (suppliers) =>
    (suppliers || []).map((supplier) => ({
      value: supplier.id,
      label: supplier.name,
    })),
);

export const getUsersMap = createSelector(
  (state: RootState) => state.commonData.users,
  (users) =>
    users.reduce((map, user) => {
      map.set(user.id, user);
      return map;
    }, new Map()),
);
