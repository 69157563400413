export enum LinkDownloaderStatus {
  NeedCreate = 'need_create',
  Created = 'created',
  NeedDelete = 'need_delete',
  Deleted = 'deleted',
  NeedUpdate = 'need_update',
  Updated = 'updated',
  NeedDownload = 'need_download',
  Downloading = 'downloading',
  Downloaded = 'downloaded',
}
