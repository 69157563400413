import React, { memo, useEffect, useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Checkbox, FormControlLabel, Grid, LinearProgress, TextField } from '@mui/material';
import LoadingButton from '../../../../shared/ui/deprecated/@extended/LoadingButton';
import { contentTypeGroupedOptions } from '../../../Job';
import { Autocomplete } from '../../../../shared/ui/deprecated/Autocomplete/Autocomplete';
import { RolesEnum } from '../../../../shared/lib/utils/constants/commonConstants';
import { getSuppliersOptions } from '../../../../shared/lib/utils/selectors/commonDataSelectors';
import { GroupedSelect } from '../../../../shared/ui/deprecated/GroupedSelect/GroupedSelect';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import {
  getEmailFormCreateLoading,
  getEmailFormData,
  getEmailFormLoading,
  getEmailFormUpdateLoading,
} from '../../model/selectors/emailFormSelector';
import { fetchEmail } from '../../model/services/fetchEmail/fetchEmail';
import { emailFormActions } from '../../model/slices/emailFormSlice';
import { updateEmail } from '../../model/services/updateEmail/updateEmail';
import { createEmail } from '../../model/services/createEmail/createEmail';

interface EmailFormProps {
  emailId?: string | null;
  onApply?: () => void;
  viewOnly?: boolean;
}

interface EmailFormValues {
  active: boolean;
  supplierId: string;
  contentType: string;
  name: string;
  fileName: string;
  sender: string;
  subject: string;
}

type FormHelpers = FormikHelpers<EmailFormValues>;

export const EmailForm = memo((props: EmailFormProps) => {
  const { emailId, onApply, viewOnly } = props;

  const form = useAppSelector(getEmailFormData);
  const loading = useAppSelector(getEmailFormLoading);
  const createLoading = useAppSelector(getEmailFormCreateLoading);
  const updateLoading = useAppSelector(getEmailFormUpdateLoading);
  const suppliersOptions = useAppSelector(getSuppliersOptions);
  const { user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (emailId) {
      dispatch(fetchEmail(emailId));
    }

    return () => {
      dispatch(emailFormActions.resetData());
    };
  }, [emailId, dispatch]);

  const validationSchema = yup.object().shape({
    contentType: yup.string().required('Выберите тип данных'),
    name: yup.string().trim().required('Укажите название'),
    sender: yup
      .string()
      .trim()
      .email('Укажите корректный email')
      .required('Укажите email отправителя'),
  });

  const initialValues: EmailFormValues = useMemo(() => {
    if (emailId) {
      return {
        active: form?.active ?? true,
        supplierId: form?.supplierId ?? '',
        contentType: form?.contentTypes[0] ?? '',
        name: form?.name ?? '',
        fileName: form?.fileName ?? '',
        sender: form?.sender ?? '',
        subject: form?.subject ?? '',
      };
    }

    return {
      active: true,
      supplierId: '',
      contentType: '',
      name: '',
      fileName: '',
      sender: '',
      subject: '',
    };
  }, [emailId, form]);

  const onSubmit = (values: EmailFormValues, { resetForm }: FormHelpers) => {
    const input = {
      active: values.active,
      supplier_id: values.supplierId || user?.supplierId,
      content_types: [values.contentType],
      name: values.name.trim(),
      format: 'xml',
      file_name: values.fileName.trim(),
      sender: values.sender.trim(),
      subject: values.subject.trim(),
    };

    if (emailId) {
      dispatch(updateEmail({ ...input, id: emailId }));
    } else {
      dispatch(createEmail({ ...input, user_id: user?.id! }));
      resetForm();
    }

    onApply?.();
  };

  if (emailId && loading) {
    return <LinearProgress color="primary" />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} alignItems={'flex-end'}>
            <Grid container item xs={10} spacing={2} style={{ marginTop: 2 }}>
              {emailId && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="active"
                        name="active"
                        checked={formik.values.active}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={viewOnly}
                      />
                    }
                    label={'Ссылка активна?'}
                  />
                </Grid>
              )}
              {!emailId && user?.role === RolesEnum.Admin && (
                <Grid item xs={4}>
                  <Autocomplete
                    id="supplierId"
                    name="supplierId"
                    label="Поставщик"
                    options={suppliersOptions}
                    value={formik.values.supplierId}
                    setFieldValue={formik.setFieldValue}
                    error={formik.touched.supplierId && Boolean(formik.errors.supplierId)}
                    helperText={formik.touched.supplierId && formik.errors.supplierId}
                    disabled={viewOnly}
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <GroupedSelect
                  id="contentType"
                  name="contentType"
                  label="Что передаем"
                  groupedOptions={contentTypeGroupedOptions}
                  value={formik.values.contentType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant={'outlined'}
                  error={formik.touched.contentType && Boolean(formik.errors.contentType)}
                  helperText={formik.touched.contentType && formik.errors.contentType}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Придумайте название"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="fileName"
                  name="fileName"
                  label="Имя файла"
                  value={formik.values.fileName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.fileName && Boolean(formik.errors.fileName)}
                  helperText={formik.touched.fileName && formik.errors.fileName}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="sender"
                  name="sender"
                  label="Email отправителя"
                  value={formik.values.sender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.sender && Boolean(formik.errors.sender)}
                  helperText={formik.touched.sender && formik.errors.sender}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="subject"
                  name="subject"
                  label="Тема письма содержит"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.subject && Boolean(formik.errors.subject)}
                  helperText={formik.touched.subject && formik.errors.subject}
                  disabled={viewOnly}
                />
              </Grid>
            </Grid>
            {!viewOnly && (
              <Grid item xs={2}>
                {emailId ? (
                  <LoadingButton
                    loading={updateLoading}
                    type="submit"
                    variant="contained"
                    loadingPosition="center"
                    fullWidth
                  >
                    {'Сохранить'}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    loading={createLoading}
                    type="submit"
                    variant="contained"
                    loadingPosition="center"
                    fullWidth
                  >
                    {'Создать'}
                  </LoadingButton>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
});
