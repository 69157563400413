import { createSlice } from '@reduxjs/toolkit';
import { camelCaseArray } from '../../../../../shared/lib/utils/formatting/formatResponse';
import { fetchSuppliersComments } from '../services/fetchSupplierComments/fetchSuppliersComments';
import { SupplierComment } from '../../../../../entities/SupplierComment';
import { loadToExcel } from '../../../../../shared/ui/deprecated/ExcelExportButton';

interface SupplierCommentsState {
  list: SupplierComment[];
  totalCount: number;
  loading: boolean;
  listForExcelLoading: boolean;
}

const initialState: SupplierCommentsState = {
  list: [],
  totalCount: 0,
  loading: false,
  listForExcelLoading: false,
};

export const suppliersCommentsSlice = createSlice({
  name: 'suppliersCommentsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliersComments.pending, (state, action) => {
      if (action.meta.arg?.forExcel) {
        state.listForExcelLoading = true;
        return;
      }
      state.loading = true;
    });
    builder.addCase(fetchSuppliersComments.fulfilled, (state, action) => {
      if (action.meta.arg?.forExcel) {
        loadToExcel(action.payload.data, 'suppliersComments');
        state.listForExcelLoading = false;
        return;
      }
      state.loading = false;
      state.list = camelCaseArray(action.payload.data) as SupplierComment[];
      state.totalCount = action.payload.total;
    });
    builder.addCase(fetchSuppliersComments.rejected, (state, action) => {
      if (action.meta.arg?.forExcel) {
        state.listForExcelLoading = false;
        return;
      }
      state.loading = false;
    });
  },
});

export const { actions: suppliersCommentsActions } = suppliersCommentsSlice;
export const { reducer: suppliersCommentsReducer } = suppliersCommentsSlice;
