export const parseArticlesFromText = (text: string) => {
  if (!text) {
    return [];
  }

  const articles = text
    .replaceAll('\n', ' ')
    .replaceAll('\r', ' ')
    .split(' ')
    .filter((val) => !!val);

  return articles;
};
