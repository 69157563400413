import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../app/deprecated/store/store';
import { SupplierOrderResponse } from '../../types/supplierOrder';
import $api from '../../../../../shared/api/api';

export const fetchSupplierOrder = createAsyncThunk<
  SupplierOrderResponse,
  string,
  ThunkConfig<string>
>('supplierOrderForm/fetchSupplierOrder', async (id, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const response = await $api.get<SupplierOrderResponse>(`/suppliers/order/${id}`);

    return response.data;
  } catch (err) {
    const errorText = `Ошибка при загрузке заказа: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
