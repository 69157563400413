import React, { memo } from 'react';
import { Card, Link, Stack, Typography } from '@mui/material';
import { getSupplierOrderFormData } from '../../model/selectors/supplierOrderFormSelectors';
import { ReactComponent as ArrowLeftIcon } from '../../../../shared/assets/icons/deprecated/arrow-left.svg';
import { getCardinalNumberString } from '../../../../shared/lib/utils/formatting/getCardinalNumberString/getCardinalNumbersString';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import { getRouteSuppliersOrders } from '../../../../shared/const/router';

interface SupplierOrderFormHeaderProps {
  className?: string;
}

export const SupplierOrderFormHeader = memo((props: SupplierOrderFormHeaderProps) => {
  const { className } = props;

  const formData = useAppSelector(getSupplierOrderFormData);

  if (!formData) {
    return null;
  }

  return (
    <Card className={className}>
      <Link href={getRouteSuppliersOrders()} underline="none">
        <Stack direction="row" alignItems="center" spacing={2} padding={2}>
          <ArrowLeftIcon />
          <Typography color="black" variant="h4">{`Заказ ${formData.orderNumber}`}</Typography>
          <Typography color="secondary">
            {getCardinalNumberString(formData.quantity, ['товар', 'товара', 'товаров'])}
          </Typography>
        </Stack>
      </Link>
    </Card>
  );
});
