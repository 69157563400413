import React, { memo } from 'react';
import { FormHelperText, Grid, Stack } from '@mui/material';
import { UploadOutlined } from '@ant-design/icons';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import LoadingButton from '../../../../shared/ui/deprecated/@extended/LoadingButton';
import UploadSingleFile from '../../../../shared/ui/deprecated/third-party/dropzone/SingleFile';
import { RolesEnum } from '../../../../shared/lib/utils/constants/commonConstants';
import { contentTypeGroupedOptions } from '../../../../entities/Job';
import { getSuppliersOptions } from '../../../../shared/lib/utils/selectors/commonDataSelectors';
import { Autocomplete } from '../../../../shared/ui/deprecated/Autocomplete/Autocomplete';
import { GroupedSelect } from '../../../../shared/ui/deprecated/GroupedSelect/GroupedSelect';
import { getCreateJobFromFileLoading } from '../model/selectors/createJobFromFileSelectors';
import { createJobFromFile } from '../model/services/createJobFromFile/createJobFromFile';
import { CreateJobInput } from '../model/types/createJobFromFile';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import ThemeCustomization from '../../../../app/deprecated/themes';

interface FormValues {
  supplierId: string;
  contentType: string;
  files: File[] | null;
}

type FormHelpers = FormikHelpers<FormValues>;

const acceptedFiles = {
  'application/xml': ['.xml'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/json': ['.json'],
  'application/zip': ['.zip'],
  'text/csv': ['.csv'],
};

export const CreateJobFromFileForm: React.FC = memo(() => {
  const dispatch = useAppDispatch();
  const jobCreateLoading = useAppSelector(getCreateJobFromFileLoading);
  const { user } = useAppSelector((state) => state.auth);
  const suppliersOptions = useAppSelector(getSuppliersOptions);

  const validationSchema = yup.object().shape({
    contentType: yup.string().required('Выберите тип данных'),
    files: yup.mixed().required('Выберите файл'),
  });

  const initialValues: FormValues = {
    supplierId: '',
    contentType: '',
    files: null,
  };

  const onSubmit = (values: FormValues, { resetForm }: FormHelpers) => {
    const data: CreateJobInput = {
      file: values.files![0],
      content_types: `["${values.contentType}"]`,
    };

    if (values.supplierId) {
      data.supplier_id = values.supplierId;
    }

    dispatch(createJobFromFile(data));
    resetForm();
  };

  return (
    <ThemeCustomization>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} alignItems={'flex-end'}>
              <Grid container item xs={10} spacing={2}>
                {user?.role === RolesEnum.Admin && (
                  <Grid item xs={6}>
                    <Autocomplete
                      id="supplierId"
                      name="supplierId"
                      label="Поставщик"
                      options={suppliersOptions}
                      value={formik.values.supplierId}
                      setFieldValue={formik.setFieldValue}
                      error={formik.touched.supplierId && Boolean(formik.errors.supplierId)}
                      helperText={formik.touched.supplierId && formik.errors.supplierId}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <GroupedSelect
                    id="contentType"
                    name="contentType"
                    label="Что передаем"
                    groupedOptions={contentTypeGroupedOptions}
                    value={formik.values.contentType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant={'outlined'}
                    error={formik.touched.contentType && Boolean(formik.errors.contentType)}
                    helperText={formik.touched.contentType && formik.errors.contentType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1.5} alignItems="center">
                    <UploadSingleFile
                      subtitle={
                        'Принимаются файлы в форматах: .xml, .xls, .xlsx, .zip, .csv, .json'
                      }
                      setFieldValue={formik.setFieldValue}
                      file={formik.values.files}
                      error={formik.touched.files && !!formik.errors.files}
                      accept={acceptedFiles}
                    />
                    {formik.touched.files && formik.errors.files && (
                      <FormHelperText error id="standard-weight-helper-text-password-login">
                        {formik.errors.files}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <LoadingButton
                  loading={jobCreateLoading}
                  type="submit"
                  variant="contained"
                  loadingPosition="start"
                  startIcon={<UploadOutlined />}
                  fullWidth
                >
                  {'Загрузить'}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </ThemeCustomization>
  );
});
