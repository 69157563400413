import React, { memo, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Grid, LinearProgress, TextField } from '@mui/material';
import { Autocomplete } from '../../../../shared/ui/deprecated/Autocomplete/Autocomplete';
import { getSuppliersOptions } from '../../../../shared/lib/utils/selectors/commonDataSelectors';
import { Select } from '../../../../shared/ui/deprecated/Select/Select';
import { rolesOptions } from '../../../../shared/lib/utils/constants/commonConstants';
import { createUser } from '../../model/services/createUser/createUser';
import { phoneRegExp } from '../../../../shared/lib/utils/validations/phoneValidations';
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../../shared/lib/hooks/useAppSelector/useAppSelector';
import { fetchUser } from '../../model/services/fetchUser/fetchUser';
import { userFormActions } from '../../model/slices/userFormSlice';
import {
  getUserFormCreateLoading,
  getUserFormData,
  getUserFormLoading,
  getUserFormUpdateLoading,
} from '../../model/selectors/userFormSelectors';
import { updateUser } from '../../model/services/updateUser/updateUser';
import LoadingButton from '../../../../shared/ui/deprecated/@extended/LoadingButton';

interface UserFormProps {
  userId?: string | null;
  onApply?: () => void;
  viewOnly?: boolean;
}

interface UserFormValues {
  id: string | null;
  name: string;
  email: string;
  phone: string;
  position: string;
  role: string;
  supplierId: string;
  password?: string;
  repeatPassword?: string;
  newPassword?: string;
  oldPassword?: string;
}

export const UserForm = memo((props: UserFormProps) => {
  const { userId, onApply, viewOnly } = props;

  const dispatch = useAppDispatch();
  const form = useAppSelector(getUserFormData);
  const loading = useAppSelector(getUserFormLoading);
  const createLoading = useAppSelector(getUserFormCreateLoading);
  const updateLoading = useAppSelector(getUserFormUpdateLoading);
  const suppliersOptions = useAppSelector(getSuppliersOptions);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId));
    }

    return () => {
      dispatch(userFormActions.resetData());
    };
  }, [userId, dispatch]);

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required('Введите ФИО'),
    email: yup
      .string()
      .trim()
      .email('Введите корректый email')
      .max(255)
      .required('Email обязателен'),
    phone: yup.string().nullable().matches(phoneRegExp, 'Введите корректный номер телефона'),
    role: yup.string().nullable().required('Выберите роль'),
    supplierId: yup.string().nullable().required('Выберите поставщика'),
    password: yup.string().when('id', {
      is: null,
      then: yup.string().trim().required('Введите пароль'),
    }),
    repeatPassword: yup.string().when('id', {
      is: null,
      then: yup.string().trim().required('Повторите пароль'),
    }),
    oldPassword: yup.string().when('newPassword', {
      is: (val: string | undefined) => !!val,
      then: yup.string().trim().required('Введите текущий пароль'),
    }),
  });

  const initialValues: UserFormValues = useMemo(() => {
    if (userId) {
      return {
        id: userId,
        name: form?.name ?? '',
        email: form?.email ?? '',
        phone: form?.phone ?? '',
        position: form?.position ?? '',
        role: form?.role ?? '',
        supplierId: form?.supplierId ?? '',
        newPassword: undefined,
        oldPassword: undefined,
      };
    }
    return {
      id: null,
      name: '',
      email: '',
      phone: '',
      position: '',
      role: '',
      supplierId: '',
      password: '',
      repeatPassword: '',
    };
  }, [userId, form]);

  const onSubmit = (values: UserFormValues) => {
    const input = {
      email: values.email,
      name: values.name,
      phone: values.phone,
      position: values.position,
      role: values.role,
      supplier_id: values.supplierId,
    };

    if (userId) {
      dispatch(
        updateUser({
          ...input,
          id: userId,
          new_password: values.newPassword,
          old_password: values.oldPassword,
        }),
      );
    } else {
      dispatch(
        createUser({
          ...input,
          password: values.password!,
          repeat_password: values.repeatPassword!,
        }),
      );
    }

    onApply?.();
  };

  if (userId && loading) {
    return <LinearProgress color="primary" />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} alignItems={'flex-end'}>
            <Grid container item xs={viewOnly ? 12 : 10} spacing={2} style={{ marginTop: 2 }}>
              <Grid container item xs={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="ФИО"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid container item xs={6}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid container item xs={6}>
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  label="Телефон"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid container item xs={6}>
                <TextField
                  fullWidth
                  id="position"
                  name="position"
                  label="Должность"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.position && Boolean(formik.errors.position)}
                  helperText={formik.touched.position && formik.errors.position}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  id="role"
                  name="role"
                  label="Выберите роль"
                  options={rolesOptions}
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant={'outlined'}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="supplierId"
                  name="supplierId"
                  label="Поставщик"
                  options={suppliersOptions}
                  value={formik.values.supplierId}
                  setFieldValue={formik.setFieldValue}
                  error={formik.touched.supplierId && Boolean(formik.errors.supplierId)}
                  helperText={formik.touched.supplierId && formik.errors.supplierId}
                  disabled={viewOnly}
                />
              </Grid>
              {userId ? (
                <>
                  <Grid container item xs={6}>
                    <TextField
                      fullWidth
                      id="newPassword"
                      name="newPassword"
                      label="Новый пароль"
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={viewOnly}
                    />
                  </Grid>
                  <Grid container item xs={6}>
                    <TextField
                      fullWidth
                      id="oldPassword"
                      name="oldPassword"
                      label="Текущий пароль"
                      value={formik.values.oldPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                      helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                      disabled={viewOnly}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container item xs={6}>
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      label="Пароль"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      disabled={viewOnly}
                    />
                  </Grid>
                  <Grid container item xs={6}>
                    <TextField
                      fullWidth
                      id="repeatPassword"
                      name="repeatPassword"
                      label="Повторите пароль"
                      value={formik.values.repeatPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                      helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                      disabled={viewOnly}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {!viewOnly && (
              <Grid item xs={2}>
                {userId ? (
                  <LoadingButton
                    loading={updateLoading}
                    type="submit"
                    variant="contained"
                    loadingPosition="center"
                    fullWidth
                  >
                    {'Сохранить'}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    loading={createLoading}
                    type="submit"
                    variant="contained"
                    loadingPosition="center"
                    fullWidth
                  >
                    {'Создать'}
                  </LoadingButton>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
});
