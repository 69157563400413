import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../app/deprecated/store/store';
import $api from '../../../../../shared/api/api';
import {
  FetchSupplierOrderItemsInput,
  SupplierOrderItemsResponse,
} from '../../types/supplierOrderItems';

export const fetchSupplierOrderItems = createAsyncThunk<
  SupplierOrderItemsResponse,
  string,
  ThunkConfig<string>
>('supplierOrders/fetchSupplierOrderItems', async (orderId, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const params: FetchSupplierOrderItemsInput = {
      // sort: input?.sort ?? `["created_at", "DESC"]`
      filter: `[{"column": "supplier_order_id", "op": "equal", "values": ["${orderId}"]}]`,
    };

    const response = await $api.get<SupplierOrderItemsResponse>('/suppliers/order_items/', {
      params: params,
    });

    return response.data;
  } catch (err) {
    const errorText = `Ошибка при загрузке товаров заказа: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  }
});
