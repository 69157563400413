import React from 'react';

export const DatabaseIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_113513_39286)">
      <path
        d="M13.9453 0H2.51674C2.20067 0 1.94531 0.255357 1.94531 0.571429V15.4286C1.94531 15.7446 2.20067 16 2.51674 16H13.9453C14.2614 16 14.5167 15.7446 14.5167 15.4286V0.571429C14.5167 0.255357 14.2614 0 13.9453 0ZM3.23103 1.28571H13.231V5H3.23103V1.28571ZM13.231 9.85714H3.23103V6.14286H13.231V9.85714ZM13.231 14.7143H3.23103V11H13.231V14.7143ZM4.51674 3.14286C4.51674 3.3323 4.592 3.51398 4.72595 3.64793C4.85991 3.78189 5.04159 3.85714 5.23103 3.85714C5.42047 3.85714 5.60215 3.78189 5.7361 3.64793C5.87006 3.51398 5.94531 3.3323 5.94531 3.14286C5.94531 2.95342 5.87006 2.77174 5.7361 2.63778C5.60215 2.50383 5.42047 2.42857 5.23103 2.42857C5.04159 2.42857 4.85991 2.50383 4.72595 2.63778C4.592 2.77174 4.51674 2.95342 4.51674 3.14286ZM4.51674 8C4.51674 8.18944 4.592 8.37112 4.72595 8.50508C4.85991 8.63903 5.04159 8.71429 5.23103 8.71429C5.42047 8.71429 5.60215 8.63903 5.7361 8.50508C5.87006 8.37112 5.94531 8.18944 5.94531 8C5.94531 7.81056 5.87006 7.62888 5.7361 7.49492C5.60215 7.36097 5.42047 7.28571 5.23103 7.28571C5.04159 7.28571 4.85991 7.36097 4.72595 7.49492C4.592 7.62888 4.51674 7.81056 4.51674 8ZM4.51674 12.8571C4.51674 13.0466 4.592 13.2283 4.72595 13.3622C4.85991 13.4962 5.04159 13.5714 5.23103 13.5714C5.42047 13.5714 5.60215 13.4962 5.7361 13.3622C5.87006 13.2283 5.94531 13.0466 5.94531 12.8571C5.94531 12.6677 5.87006 12.486 5.7361 12.3521C5.60215 12.2181 5.42047 12.1429 5.23103 12.1429C5.04159 12.1429 4.85991 12.2181 4.72595 12.3521C4.592 12.486 4.51674 12.6677 4.51674 12.8571Z"
        fill="#8C8C8C"
      />
    </g>
    <defs>
      <clipPath id="clip0_113513_39286">
        <rect width="16" height="16" fill="white" transform="translate(0.231445)" />
      </clipPath>
    </defs>
  </svg>
);
