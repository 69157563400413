import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: 'Graphik, sans-serif',
    fontSize: 13,
    font28reg: {
      fontSize: 28,
      fontWeight: 400,
    },
    font22reg: {
      fontSize: 22,
      fontWeight: 400,
    },
    font16med: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '22px',
    },
    font13med: {
      fontSize: 13,
      fontWeight: 500,
      lineHeight: '18px',
    },
    font13reg: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '18px',
    },
    font11med: {
      fontSize: 11,
      fontWeight: 500,
      lineHeight: '18px',
    },
  },
  palette: {
    black: {
      main: '#0B0B0F',
      contrastText: '#fff',
    },
    darkGray: {
      main: '#2E3B47',
    },
    gray: {
      main: '#7A7A99',
    },
    grayHover: {
      main: '#464853',
    },
    mediumGray: {
      main: '#CDCFD5',
    },
    mediumGrayHover: {
      main: '#ACAEB9',
    },
    blue: {
      main: '#4959E9',
      contrastText: '#fff',
    },
    blueHover: {
      main: '#101B7F',
      contrastText: '#fff',
    },
    border: {
      main: '#E7EDF3',
    },
    borderHover: {
      main: '#CED9E5',
    },
    grayBg: {
      main: '#F5F6F9',
    },
    white: {
      main: '#FFFFFF',
    },
    white64: {
      main: '#FFFFFFAA',
    },
    hover: {
      main: '#E9EFF7',
    },
    disabled: {
      main: '#C1C1C3',
      contrastText: '#fff',
    },
    tableTitle: {
      main: '#F9F9FB',
    },
    selected: {
      main: '#D7E4F4',
    },
    selectedHover: {
      main: '#CDDCEE',
    },
    error: {
      main: '#F65154',
    },
    warning: {
      main: '#FD9448',
    },
    green: {
      main: '#078D34',
    },
    successBase: {
      main: '#D1E6D8',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
          height: 40,
        },
        startIcon: {
          marginLeft: 0,
          marginRight: 6,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(270deg, #CCD9E6 0%, #E7EDF3 100%)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#0B0B0F',
        },
        arrow: {
          color: '#0B0B0F',
        },
      },
    },
  },
});
