import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../../../entities/User';
import { Supplier } from '../../../../entities/Supplier/model/types/supplier';

interface CommonDataState {
  commonDataLoading: boolean;
  suppliers: Supplier[];
  users: User[];
}

const initialState: CommonDataState = {
  commonDataLoading: false,
  suppliers: [],
  users: [],
};

export const commonDataSlice = createSlice({
  name: 'commonDataSlice',
  initialState,
  reducers: {
    setCommonDataLoading(state, { payload }: PayloadAction<boolean>) {
      state.commonDataLoading = payload;
    },
    setSuppliers(state, { payload }: PayloadAction<CommonDataState['suppliers']>) {
      state.suppliers = payload;
    },
    setUsers(state, { payload }: PayloadAction<CommonDataState['users']>) {
      state.users = payload;
    },
  },
});

export default commonDataSlice.reducer;

export const { setCommonDataLoading, setSuppliers, setUsers } = commonDataSlice.actions;
