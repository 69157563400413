import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSnackbar } from '../../../../../../app/deprecated/store/reducers/snackbarSlice';
import { ThunkConfig } from '../../../../../../app/deprecated/store/store';
import $api from '../../../../../../shared/api/api';
import { JobResponse, jobsActions } from '../../../../../../entities/Job';
import { CreateJobFromLinkDataInput } from '../../types/createJobFromLinkData';

export const createJobFromLinkData = createAsyncThunk<
  JobResponse,
  CreateJobFromLinkDataInput,
  ThunkConfig<string>
>('job/createJobFromLinkData', async (input, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const response = await $api.post<JobResponse>(`/jobs/link_data`, input);

    dispatch(
      openSnackbar({
        open: true,
        message: 'Загрузка создана',
        variant: 'alert',
        alert: {
          color: 'success',
        },
      }),
    );

    return response.data;
  } catch (err) {
    const errorText = `Ошибка при создании загрузки: ${
      err instanceof Error ? err.message : String(err)
    }`;
    dispatch(
      openSnackbar({
        open: true,
        message: errorText,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      }),
    );

    return rejectWithValue(errorText);
  } finally {
    dispatch(jobsActions.setNeedReloadJobs(true));
  }
});
