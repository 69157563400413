import React, { memo } from 'react';
import { ListItem, ListItemText } from '@mui/material';

interface FormListItemProps {
  className?: string;
  title: string;
  text: string;
}

export const OrderInfoListItem = memo((props: FormListItemProps) => {
  const { className, title, text } = props;

  return (
    <ListItem disablePadding>
      <ListItemText
        className={className}
        primary={title}
        primaryTypographyProps={{ color: 'secondary' }}
        secondary={text}
        secondaryTypographyProps={{ color: 'text', variant: 'inherit' }}
      />
    </ListItem>
  );
});
