// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SupplierOrderItems_orderItem__3cgDM {\n  display: flex;\n  justify-content: space-between;\n}\n\n.SupplierOrderItems_orderItemDataItem__13bVX {\n  margin-top: 8px;\n}\n\n.SupplierOrderItems_orderItemPriceBlock__28-zz {\n  flex-shrink: 0;\n}\n\n.SupplierOrderItems_orderItemPrice__3aSzn {\n  display: flex;\n  grid-gap: 16px;\n  gap: 16px;\n  padding: 8px 18px;\n  background: #FAFAFA;\n  border-radius: 4px;\n  font-size: 12px;\n}", "",{"version":3,"sources":["webpack://src/entities/SupplierOrder/ui/SupplierOrderItems/SupplierOrderItems.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,cAAA;EAAA,SAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AACF","sourcesContent":[".orderItem {\n  display: flex;\n  justify-content: space-between;\n}\n\n.orderItemDataItem {\n  margin-top: 8px;\n}\n\n.orderItemPriceBlock {\n  flex-shrink: 0;\n}\n\n.orderItemPrice {\n  display: flex;\n  gap: 16px;\n  padding: 8px 18px;\n  background: #FAFAFA;\n  border-radius: 4px;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderItem": "SupplierOrderItems_orderItem__3cgDM",
	"orderItemDataItem": "SupplierOrderItems_orderItemDataItem__13bVX",
	"orderItemPriceBlock": "SupplierOrderItems_orderItemPriceBlock__28-zz",
	"orderItemPrice": "SupplierOrderItems_orderItemPrice__3aSzn"
};
export default ___CSS_LOADER_EXPORT___;
